import React, { useEffect, useState } from "react";
import { Loader, apiUrl } from "../../Utils/Consts";
import { PDFDocument } from "pdf-lib";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

async function convertBytesToPDFAndDownload(byteArray, fileName) {
    try {
        const pdfDoc = await PDFDocument.load(byteArray);
        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], { type: "application/pdf" });
        saveAs(blob, fileName);
    } catch (error) {
        console.error("Error converting byte array to PDF:", error);
    }
}

export const ReportList = () => {
    const [reportList, setReportList] = useState(null);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [searchClicked, setSearchClicked] = useState(false);

    const getReportList = () => {
        const formattedStartDate = selectedStartDate
            ? selectedStartDate.toLocaleDateString("en-GB")
            : "";
        const formattedEndDate = selectedEndDate
            ? selectedEndDate.toLocaleDateString("en-GB")
            : "";

        if (selectedStartDate && selectedEndDate) {
            const dateDiffInDays = Math.ceil(
                (selectedEndDate - selectedStartDate) / (1000 * 60 * 60 * 24)
            );
            if (dateDiffInDays > 31) {
                alert("Please select a date range of maximum 31 days.");
                return;
            }
        }
        console.log(`${apiUrl}/report/list?accountId=${localStorage.getItem(
            "accountId"
        )}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`)
        fetch(
            `${apiUrl}/report/list?accountId=${localStorage.getItem(
                "accountId"
            )}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        )
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        setReportList(res.yetkiBelgesiKontrolData.sonucKodu == 34 ? "error" : res.reportList);
                    }
                },
                (error) => {
                    console.error("Error fetching report list:", error);
                }
            );
    };

    const reportPdf = (reportReferralNumber) => {
        const formattedStartDate = selectedStartDate
            ? selectedStartDate.toLocaleDateString("en-GB")
            : "";
        const formattedEndDate = selectedEndDate
            ? selectedEndDate.toLocaleDateString("en-GB")
            : "";

        fetch(
            `${apiUrl}/report/pdf?accountId=${localStorage.getItem(
                "accountId"
            )}&reportReferralNumber=${reportReferralNumber}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
        )
            .then((res) => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        convertBytesToPDFAndDownload(
                            res.reportPdf,
                            `${reportReferralNumber}.pdf`
                        );
                    }
                },
                (error) => {
                    console.error("Error fetching report PDF:", error);
                }
            );
    };

    useEffect(() => {
        getReportList();
    }, []);

    useEffect(() => {
        if (searchClicked && selectedStartDate && selectedEndDate) {
            getReportList();
            setSearchClicked(false); // Reset the search button state after the API request
        }
    }, [searchClicked, selectedStartDate, selectedEndDate]);

    return (
        <div className="list-page _page">
            <div className="date-picker-container custom-datepicker date-filter">
                <div className="date">
                    <span className="date-title">Başlangıç Tarihi</span>
                    <DatePicker
                        selected={selectedStartDate}
                        onChange={(date) => setSelectedStartDate(date)}
                        maxDate={selectedEndDate ? selectedEndDate : new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                <div className="date">
                    <span className="date-title">Bitiş Tarihi</span>
                    <DatePicker
                        selected={selectedEndDate}
                        onChange={(date) => setSelectedEndDate(date)}
                        minDate={selectedStartDate}
                        maxDate={new Date()}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="dd/MM/yyyy"
                    />
                </div>
                <button className="search"
                    disabled={!selectedStartDate || !selectedEndDate}
                    onClick={() => setSearchClicked(true)}
                >Ara</button>
            </div>


            {reportList ? (
                <div className="report-list">
                    <span className="title">Seferler ({reportList != "error" ? reportList.length : "Hata"})</span>
                    <table>
                        <thead>
                            <tr>
                                <th>Durum</th>
                                <th>Plaka</th>
                                <th>Tarih</th>
                                <th>Pdf</th>
                            </tr>
                        </thead>
                        <tbody>
                            {reportList && reportList !== "error" ? (
                                reportList.map((e) => (
                                    <tr key={e.uetdsSeferReferansNo}>
                                        <td>{e.seferDurum}</td>
                                        <td>{e.seferPlaka}</td>
                                        <td>{e.bildirimZamani}</td>
                                        <td
                                            className="pdf-download"
                                            onClick={() => reportPdf(e.uetdsSeferReferansNo)}
                                        >
                                            İndir
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4">Aktif yetki belgeniz bulunmamaktadır, talep etmek için bize ulaşınız.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            ) : (
                <Loader />
            )}
        </div>
    );
};
