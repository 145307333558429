import { useEffect, useState } from "react"
import { apiUrl } from "../../Utils/Consts"

export const DriverList = () => {
    const [driverList, setDriverList] = useState()
    const getDriverList = () => {
        fetch(`${apiUrl}/driver/list`)
            .then(res => res.json())
            .then(
                (res) => {
                    console.log(res)
                    if (res.responseStatus) {
                        setDriverList(res.driverList)
                    }
                },
                (error) => {
                }
            )
    }
    useEffect(() => {
        getDriverList()
    }, [])
    return (
        driverList ?
            <div className="driver-list">
                <span className="title">Sürücüler ({driverList.length})</span>
                <table>
                    <tr>
                        <th>İsim</th>
                        <th>Plaka</th>
                        <th>E-posta</th>
                        <th>Tarih</th>
                    </tr>
                    {driverList.map(e =>
                        <tr>
                            <td>{e.driverName}</td>
                            <td>{e.driverVehiclePlate}</td>
                            <td>{e.driverEmail}</td>
                            <td>{e.created_at}</td>
                        </tr>
                    )}
                </table>
            </div>

            :
            <span>Loading...</span>
    )
}