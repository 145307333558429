import { useEffect, useState } from "react";
import { Loader, Space, apiUrl, countries } from "../../Utils/Consts";
import { CountrySelectBox, SelectBox } from "../../Utils/CountrySelectBox";
import { provinces } from "../../Utils/Consts";
import { ProvinceSelectBox } from "../../Utils/ProvinceSelectBox";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

export const ReportCreate = () => {
    const [inputValues, setInputValues] = useState([]);
    const [reportPrice, setReportPrice] = useState();
    const [reportStartLocation, setReportStartLocation] = useState();
    const [reportEndLocation, setReportEndLocation] = useState();
    const [reportStartDateTime, setReportStartDateTime] = useState();
    const [reportEndDateTime, setReportEndDateTime] = useState();
    const [accountId, setAccountId] = useState();
    const [reportCreateState, setReportCreateState] = useState(null); // Initialize as null
    const navigate = useNavigate();

    const handleInputChange = (e, index, fieldName) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = {
            ...newInputValues[index],
            [fieldName]: e.target.value,
        };
        setInputValues(newInputValues);
    };

    const addInput = () => {
        setInputValues([...inputValues, { passengerName: "", passengerCountry: "" }]);
    };

    const handleSelectChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = {
            ...newInputValues[index],
            passengerCountry: value,
        };
        setInputValues(newInputValues);
    };

    const removeInput = (index) => {
        const newInputValues = [...inputValues];
        newInputValues.splice(index, 1);
        setInputValues(newInputValues);
    };

    const renderInputs = () => {
        return inputValues.map((value, index) => (
            <div className="passenger">
                <span className="passenger_title">{index + 1}. Yolcu</span>
                <Space height={16} />
                <div className="group" key={index}>
                    <div className="section">
                        <span className="label">Ad Soyad</span>
                        <input
                            value={value.passengerName}
                            onChange={(e) => handleInputChange(e, index, "passengerName")}
                        />
                    </div>
                    <div className="section">
                        <span className="label">Uyruk</span>
                        <CountrySelectBox
                            placeholder="Ülke Seç"
                            items={countries}
                            onChange={(selectedValue) =>
                                handleSelectChange(index, selectedValue)
                            }
                        />
                    </div>
                </div>
                {index == 0 ? null : <span className="button delete" onClick={() => removeInput(index)}>{index + 1}. Yolcuyu Sil</span>
                }
                <Space height={32} />
            </div>

        ));
    };

    useEffect(() => {
        setInputValues([{ passengerName: "", passengerCountry: "" }]);
    }, []);

    const reportCreate = () => {
        // Check if reportPrice is valid
        return new Promise((resolve, reject) => {
            const parsedReportPrice = parseInt(reportPrice, 10);
            if (isNaN(parsedReportPrice)) {
                reject("Sefer ücreti girilmelidir.");
            } else if (
                !reportStartDateTime ||
                !reportEndDateTime ||
                !reportStartLocation ||
                !reportEndLocation
            ) {
                // Check if all required fields are filled
                reject("Tarih ve il seçimi yapın.");
            } else {
                const currentDate = new Date();
                const startDateTime = new Date(reportStartDateTime);
                const endDateTime = new Date(reportEndDateTime);

                // Check if reportEndDate is greater than reportStartDate
                if (endDateTime <= startDateTime) {
                    reject(
                        "Bitiş tarihi, başlangıç tarihinden daha ileri bir tarih olmalıdır."
                    );
                } else {
                    // Check if startDateTime is at least 1 hour in the future
                    const timeDifference = startDateTime.getTime() - currentDate.getTime();
                    const minimumTimeDifference = 1 * 60 * 60 * 1000; // 1 hour in milliseconds

                    if (timeDifference < minimumTimeDifference) {
                        reject(
                            "Başlangıç tarihi en az 1 saat sonrasını içermelidir."
                        );
                    } else if (inputValues.some((value) => !value.passengerName || !value.passengerCountry)) {
                        // Check if any passenger name or country is empty
                        reject("Yolcu bilgilerini eksiksiz doldurun.");
                    } else {
                        const requestBody = {
                            accountId: localStorage.getItem("accountId"),
                            reportData: {
                                reportPrice,
                                reportStartLocation,
                                reportStartDateTime,
                                reportEndLocation,
                                reportEndDateTime,
                                passengerList: inputValues,
                                accountId
                            },
                        };
                        const requestMetadata = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(requestBody),
                        };
                        fetch(`${apiUrl}/report/create`, requestMetadata)
                            .then((res) => res.json())
                            .then(
                                (res) => {
                                    console.log(res);
                                    if (res.responseStatus) {
                                        resolve()
                                        navigate("/reports");
                                    }
                                    else {
                                        reject("Bir hata oluştu.")
                                    }
                                },
                                (error) => {
                                    // Handle error if needed
                                }
                            );
                    }
                }
            }
        })
    };
    const [companyData, setCompanyData] = useState()
    const getCompanyData = () => {
        fetch(`${apiUrl}/company/data?accountId=${localStorage.getItem("accountId")}`)
            .then(res => res.json())
            .then(
                (res) => {
                    console.log(res)
                    setCompanyData(res)
                    setAccountId(res.company_data.account_list.find(e => e.accountId == localStorage.getItem("accountId")).accountId)
                },
                (error) => {
                }
            )
    }
    useEffect(() => {
        getCompanyData()
    }, [])
    return (
        companyData ?
            <div className="report-create _page _page_report_create">
                <Space height={16} />
                <div className="group">
                    <div className="section">
                        <span className="label" default>Şoför</span>
                        <select defaultValue={companyData.company_data.account_list.find(e => e.accountId == localStorage.getItem("accountId")).accountId} onChange={e => setAccountId(e.target.value)}>
                            {companyData.company_data.account_list.map(e =>
                                <option
                                    key={e.accountId}
                                    value={e.accountId}
                                >
                                    {e.accountName}
                                </option>
                            )}
                        </select>
                    </div>
                    <div className="section">
                        <span className="label">Ücret (TL)</span>
                        <input
                            type="number"
                            value={reportPrice}
                            onChange={(e) => setReportPrice(e.target.value)}
                        />
                    </div>
                </div>
                <Space height={16} />
                <div className="group">
                    <div className="section">
                        <span className="label">Alış Yeri</span>
                        <ProvinceSelectBox
                            placeholder={"İl, ilçe, havalimanı ara..."}
                            items={provinces}
                            onChange={(selectedValue) => setReportStartLocation(selectedValue)}
                        />
                    </div>
                    <div className="section">
                        <span className="label">Varış Yeri</span>
                        <ProvinceSelectBox
                            placeholder={"İl, ilçe, havalimanı ara..."}
                            items={provinces}
                            onChange={(selectedValue) => setReportEndLocation(selectedValue)}
                        />
                    </div>
                </div>
                <Space height={16} />
                <div className="group">
                    <div className="section">
                        <span className="label">Alış Tarihi</span>
                        <input
                            type="datetime-local"
                            onChange={(e) => setReportStartDateTime(e.target.value)}
                        />
                    </div>
                    <div className="section">
                        <span className="label">Bitiş Tarihi</span>
                        <input
                            type="datetime-local"
                            onChange={(e) => setReportEndDateTime(e.target.value)}
                        />
                    </div>
                </div>
                <Space height={36} />
                <span className="title">Yolcular</span>
                <Space height={12} />
                {renderInputs()}
                <span className="button add-passenger" onClick={addInput}>Yolcu Ekle</span>
                <Space height={48} />
                <span className="button" onClick={() => {
                    toast.promise(
                        reportCreate(),
                        {
                            loading: 'Yükleniyor',
                            success: 'Sefer başarıyla eklendi.',
                            error: (err) => err,
                        },
                    );
                }

                }>Gönder</span>
                <span className="error">{reportCreateState}</span>
                <Space height={32} />

            </div>
            :
            <Loader />
    );
};