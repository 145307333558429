export const apiUrl = "https://api.gotransfer.app/uetds"
export const Loader = () => (
    <div class="_loader _page">
        <svg viewBox="0 0 32 32" width="42" height="42">
            <circle id="spinner" cx="16" cy="16" r="14" fill="none"></circle>
        </svg>
    </div>
)
export const Space = ({ width, height }) => (
    <div style={{ minWidth: `${width}px`, minHeight: `${height}px` }}></div>
)
export const countries = [
    {
        "KOD": "TR",
        "ULKE_ADI": "Türkiye"
    },
    {
        "KOD": "OA",
        "ULKE_ADI": "Abd Minor Outlying Adaları"
    },
    {
        "KOD": "AF",
        "ULKE_ADI": "Afganistan"
    },
    {
        "KOD": "DE",
        "ULKE_ADI": "Almanya"
    },
    {
        "KOD": "US",
        "ULKE_ADI": "Amerika Birleşik Devletleri"
    },
    {
        "KOD": "AS",
        "ULKE_ADI": "Amerika Samoası"
    },
    {
        "KOD": "OC",
        "ULKE_ADI": "Amerikan Okyanusyası"
    },
    {
        "KOD": "AD",
        "ULKE_ADI": "Andorra"
    },
    {
        "KOD": "AO",
        "ULKE_ADI": "Angola"
    },
    {
        "KOD": "AI",
        "ULKE_ADI": "Anguılla"
    },
    {
        "KOD": "AQ",
        "ULKE_ADI": "Antartika"
    },
    {
        "KOD": "AG",
        "ULKE_ADI": "Antıgua Ve Bermuda"
    },
    {
        "KOD": "AR",
        "ULKE_ADI": "Arjantin"
    },
    {
        "KOD": "AL",
        "ULKE_ADI": "Arnavutluk"
    },
    {
        "KOD": "AW",
        "ULKE_ADI": "Aruba"
    },
    {
        "KOD": "AU",
        "ULKE_ADI": "Avustralya"
    },
    {
        "KOD": "UO",
        "ULKE_ADI": "Avustralya Okyanusu"
    },
    {
        "KOD": "AT",
        "ULKE_ADI": "Avusturya"
    },
    {
        "KOD": "AZ",
        "ULKE_ADI": "Azerbaycan - Nahçıvan"
    },
    {
        "KOD": "BS",
        "ULKE_ADI": "Bahamalar"
    },
    {
        "KOD": "BH",
        "ULKE_ADI": "Bahreyn"
    },
    {
        "KOD": "BD",
        "ULKE_ADI": "Bangladeş"
    },
    {
        "KOD": "BB",
        "ULKE_ADI": "Barbados"
    },
    {
        "KOD": "BE",
        "ULKE_ADI": "Belçika"
    },
    {
        "KOD": "BZ",
        "ULKE_ADI": "Belize"
    },
    {
        "KOD": "BJ",
        "ULKE_ADI": "Benin"
    },
    {
        "KOD": "BM",
        "ULKE_ADI": "Bermuda"
    },
    {
        "KOD": "BY",
        "ULKE_ADI": "Beyaz Rusya"
    },
    {
        "KOD": "BT",
        "ULKE_ADI": "Bhutan"
    },
    {
        "KOD": "AE",
        "ULKE_ADI": "Birleşik Arap Emirlikleri"
    },
    {
        "KOD": "BAC",
        "ULKE_ADI": "Bismark Archipelago"
    },
    {
        "KOD": "BO",
        "ULKE_ADI": "Bolivya"
    },
    {
        "KOD": "BA",
        "ULKE_ADI": "Bosna -Hersek"
    },
    {
        "KOD": "BW",
        "ULKE_ADI": "Bostvana"
    },
    {
        "KOD": "BVA",
        "ULKE_ADI": "Bouvet Adası"
    },
    {
        "KOD": "BR",
        "ULKE_ADI": "Brezilya"
    },
    {
        "KOD": "BN",
        "ULKE_ADI": "Brunei"
    },
    {
        "KOD": "BG",
        "ULKE_ADI": "Bulgaristan"
    },
    {
        "KOD": "BF",
        "ULKE_ADI": "Burkina Faso"
    },
    {
        "KOD": "BI",
        "ULKE_ADI": "Burundi"
    },
    {
        "KOD": "CV",
        "ULKE_ADI": "Cape Verde"
    },
    {
        "KOD": "KY",
        "ULKE_ADI": "Cayman Adaları"
    },
    {
        "KOD": "GI",
        "ULKE_ADI": "Cebeli Tarık"
    },
    {
        "KOD": "DZ",
        "ULKE_ADI": "Cezayir"
    },
    {
        "KOD": "CAD",
        "ULKE_ADI": "Chrıstmas Adaları"
    },
    {
        "KOD": "DJ",
        "ULKE_ADI": "Cibuti"
    },
    {
        "KOD": "CC",
        "ULKE_ADI": "Cocos Adaları"
    },
    {
        "KOD": "CK",
        "ULKE_ADI": "Cook Adaları"
    },
    {
        "KOD": "TD",
        "ULKE_ADI": "Çad"
    },
    {
        "KOD": "CX",
        "ULKE_ADI": "Çeçen Cumhuriyeti"
    },
    {
        "KOD": "CZ",
        "ULKE_ADI": "Çek Cumhuriyeti"
    },
    {
        "KOD": "CN",
        "ULKE_ADI": "Çin Halk Cumhuriyeti"
    },
    {
        "KOD": "DC",
        "ULKE_ADI": "Dağıstan Cumhuriyeti"
    },
    {
        "KOD": "DK",
        "ULKE_ADI": "Danimarka"
    },
    {
        "KOD": "DT",
        "ULKE_ADI": "Doğu Timor"
    },
    {
        "KOD": "DO",
        "ULKE_ADI": "Dominik Cumhuriyeti"
    },
    {
        "KOD": "DM",
        "ULKE_ADI": "Dominika"
    },
    {
        "KOD": "DB",
        "ULKE_ADI": "Dubai"
    },
    {
        "KOD": "EC",
        "ULKE_ADI": "Ekvator"
    },
    {
        "KOD": "GQ",
        "ULKE_ADI": "Ekvator Ginesi"
    },
    {
        "KOD": "SV",
        "ULKE_ADI": "El Salvador"
    },
    {
        "KOD": "ID",
        "ULKE_ADI": "Endonezya"
    },
    {
        "KOD": "ER",
        "ULKE_ADI": "Eritre"
    },
    {
        "KOD": "AM",
        "ULKE_ADI": "Ermenistan"
    },
    {
        "KOD": "EE",
        "ULKE_ADI": "Estonya"
    },
    {
        "KOD": "ET",
        "ULKE_ADI": "Etiyopya"
    },
    {
        "KOD": "FK",
        "ULKE_ADI": "Falkland Adaları"
    },
    {
        "KOD": "FO",
        "ULKE_ADI": "Faroe Adaları"
    },
    {
        "KOD": "MA",
        "ULKE_ADI": "Fas"
    },
    {
        "KOD": "FJ",
        "ULKE_ADI": "Fiji"
    },
    {
        "KOD": "CI",
        "ULKE_ADI": "Fildişi Sahili"
    },
    {
        "KOD": "PH",
        "ULKE_ADI": "Filipinler"
    },
    {
        "KOD": "PS",
        "ULKE_ADI": "Filistin"
    },
    {
        "KOD": "FI",
        "ULKE_ADI": "Finlandiya"
    },
    {
        "KOD": "FR",
        "ULKE_ADI": "Fransa"
    },
    {
        "KOD": "GF",
        "ULKE_ADI": "Fransız Guyanası"
    },
    {
        "KOD": "PF",
        "ULKE_ADI": "Fransız Polinezyası"
    },
    {
        "KOD": "GA",
        "ULKE_ADI": "Gabon"
    },
    {
        "KOD": "GM",
        "ULKE_ADI": "Gambiya"
    },
    {
        "KOD": "GH",
        "ULKE_ADI": "Gana"
    },
    {
        "KOD": "GN",
        "ULKE_ADI": "Gine"
    },
    {
        "KOD": "GW",
        "ULKE_ADI": "Gine-Bissau"
    },
    {
        "KOD": "GD",
        "ULKE_ADI": "Grenada"
    },
    {
        "KOD": "GL",
        "ULKE_ADI": "Grönland"
    },
    {
        "KOD": "GP",
        "ULKE_ADI": "Guadelup"
    },
    {
        "KOD": "GU",
        "ULKE_ADI": "Guam"
    },
    {
        "KOD": "GT",
        "ULKE_ADI": "Guatemala"
    },
    {
        "KOD": "GY",
        "ULKE_ADI": "Guyana"
    },
    {
        "KOD": "ZA",
        "ULKE_ADI": "Güney Afrika Cumhuriyeti"
    },
    {
        "KOD": "GFR",
        "ULKE_ADI": "Güney Fransız Toprakları"
    },
    {
        "KOD": "GGS",
        "ULKE_ADI": "Güney Georgıa Ve Güney Sandwıch Adaları"
    },
    {
        "KOD": "KR",
        "ULKE_ADI": "Güney Kore Cumhuriyeti"
    },
    {
        "KOD": "GE",
        "ULKE_ADI": "Gürcistan"
    },
    {
        "KOD": "HT",
        "ULKE_ADI": "Haiti"
    },
    {
        "KOD": "HAD",
        "ULKE_ADI": "Heard Adaları Ve Mc Donald Adaları"
    },
    {
        "KOD": "HR",
        "ULKE_ADI": "Hırvatistan"
    },
    {
        "KOD": "IN",
        "ULKE_ADI": "Hindistan"
    },
    {
        "KOD": "NL",
        "ULKE_ADI": "Hollanda"
    },
    {
        "KOD": "AN",
        "ULKE_ADI": "Hollanda Antilleri"
    },
    {
        "KOD": "HN",
        "ULKE_ADI": "Honduras"
    },
    {
        "KOD": "HK",
        "ULKE_ADI": "Hong Kong"
    },
    {
        "KOD": "IQ",
        "ULKE_ADI": "Irak"
    },
    {
        "KOD": "IO",
        "ULKE_ADI": "İngiliz Hint Oky.Toprakları"
    },
    {
        "KOD": "VG",
        "ULKE_ADI": "İngiliz Virjin Adaları"
    },
    {
        "KOD": "GB",
        "ULKE_ADI": "İngiltere"
    },
    {
        "KOD": "IR",
        "ULKE_ADI": "İran"
    },
    {
        "KOD": "IE",
        "ULKE_ADI": "İrlanda"
    },
    {
        "KOD": "ES",
        "ULKE_ADI": "İspanya"
    },
    {
        "KOD": "IL",
        "ULKE_ADI": "İsrail"
    },
    {
        "KOD": "SE",
        "ULKE_ADI": "İsveç"
    },
    {
        "KOD": "CH",
        "ULKE_ADI": "İsviçre"
    },
    {
        "KOD": "IT",
        "ULKE_ADI": "İtalya"
    },
    {
        "KOD": "IS",
        "ULKE_ADI": "İzlanda"
    },
    {
        "KOD": "JM",
        "ULKE_ADI": "Jamaika"
    },
    {
        "KOD": "JP",
        "ULKE_ADI": "Japonya"
    },
    {
        "KOD": "KH",
        "ULKE_ADI": "Kamboçya"
    },
    {
        "KOD": "CM",
        "ULKE_ADI": "Kamerun"
    },
    {
        "KOD": "CA",
        "ULKE_ADI": "Kanada"
    },
    {
        "KOD": "MW",
        "ULKE_ADI": "Karadağ"
    },
    {
        "KOD": "QA",
        "ULKE_ADI": "Katar"
    },
    {
        "KOD": "KZ",
        "ULKE_ADI": "Kazakistan"
    },
    {
        "KOD": "KE",
        "ULKE_ADI": "Kenya"
    },
    {
        "KOD": "CY",
        "ULKE_ADI": "Kıbrıs Rum Kesimi"
    },
    {
        "KOD": "KG",
        "ULKE_ADI": "Kırgızistan"
    },
    {
        "KOD": "KI",
        "ULKE_ADI": "Kiribati"
    },
    {
        "KOD": "CO",
        "ULKE_ADI": "Kolombiya"
    },
    {
        "KOD": "KM",
        "ULKE_ADI": "Komoro Adaları"
    },
    {
        "KOD": "CG",
        "ULKE_ADI": "Kongo"
    },
    {
        "KOD": "K",
        "ULKE_ADI": "Kosova"
    },
    {
        "KOD": "CR",
        "ULKE_ADI": "Kosta Rika"
    },
    {
        "KOD": "KB",
        "ULKE_ADI": "Kutup Bölgeleri"
    },
    {
        "KOD": "KW",
        "ULKE_ADI": "Kuveyt"
    },
    {
        "KOD": "KKTC",
        "ULKE_ADI": "Kuzey Kıbrıs Türk Cumhuriyeti"
    },
    {
        "KOD": "KP",
        "ULKE_ADI": "Kuzey Kore Demokratik Halk Cum."
    },
    {
        "KOD": "MK",
        "ULKE_ADI": "Kuzey Makedonya"
    },
    {
        "KOD": "MP",
        "ULKE_ADI": "Kuzey Mariana Adaları"
    },
    {
        "KOD": "CU",
        "ULKE_ADI": "Küba"
    },
    {
        "KOD": "LA",
        "ULKE_ADI": "Laos"
    },
    {
        "KOD": "LS",
        "ULKE_ADI": "Lesotho"
    },
    {
        "KOD": "LV",
        "ULKE_ADI": "Letonya"
    },
    {
        "KOD": "LR",
        "ULKE_ADI": "Liberya"
    },
    {
        "KOD": "LY",
        "ULKE_ADI": "Libya"
    },
    {
        "KOD": "LI",
        "ULKE_ADI": "Lihteştayn"
    },
    {
        "KOD": "LT",
        "ULKE_ADI": "Litvanya"
    },
    {
        "KOD": "LB",
        "ULKE_ADI": "Lübnan"
    },
    {
        "KOD": "LU",
        "ULKE_ADI": "Lüksemburg"
    },
    {
        "KOD": "HU",
        "ULKE_ADI": "Macaristan"
    },
    {
        "KOD": "MG",
        "ULKE_ADI": "Madagaskar"
    },
    {
        "KOD": "MO",
        "ULKE_ADI": "Makao"
    },
    {
        "KOD": "MI",
        "ULKE_ADI": "Malavi"
    },
    {
        "KOD": "MV",
        "ULKE_ADI": "Maldiv Adaları"
    },
    {
        "KOD": "MY",
        "ULKE_ADI": "Malezya"
    },
    {
        "KOD": "ML",
        "ULKE_ADI": "Mali"
    },
    {
        "KOD": "MT",
        "ULKE_ADI": "Malta"
    },
    {
        "KOD": "MH",
        "ULKE_ADI": "Marshall Adaları"
    },
    {
        "KOD": "MQ",
        "ULKE_ADI": "Martinik"
    },
    {
        "KOD": "MU",
        "ULKE_ADI": "Mauritius"
    },
    {
        "KOD": "YT",
        "ULKE_ADI": "Mayotte"
    },
    {
        "KOD": "MX",
        "ULKE_ADI": "Meksika"
    },
    {
        "KOD": "CS",
        "ULKE_ADI": "Melilla"
    },
    {
        "KOD": "EG",
        "ULKE_ADI": "Mısır"
    },
    {
        "KOD": "FM",
        "ULKE_ADI": "Mikronezya"
    },
    {
        "KOD": "MN",
        "ULKE_ADI": "Moğolistan"
    },
    {
        "KOD": "MD",
        "ULKE_ADI": "Moldova"
    },
    {
        "KOD": "MC",
        "ULKE_ADI": "Monaka"
    },
    {
        "KOD": "MS",
        "ULKE_ADI": "Montserrat"
    },
    {
        "KOD": "MR",
        "ULKE_ADI": "Moritanya"
    },
    {
        "KOD": "MZ",
        "ULKE_ADI": "Mozambik"
    },
    {
        "KOD": "MM",
        "ULKE_ADI": "Myanmar"
    },
    {
        "KOD": "NA",
        "ULKE_ADI": "Namibya"
    },
    {
        "KOD": "NR",
        "ULKE_ADI": "Nauru"
    },
    {
        "KOD": "NP",
        "ULKE_ADI": "Nepal"
    },
    {
        "KOD": "NE",
        "ULKE_ADI": "Nijer"
    },
    {
        "KOD": "NG",
        "ULKE_ADI": "Nijerya"
    },
    {
        "KOD": "NI",
        "ULKE_ADI": "Nikaragua"
    },
    {
        "KOD": "NU",
        "ULKE_ADI": "Niue"
    },
    {
        "KOD": "NF",
        "ULKE_ADI": "Norfolk Adası"
    },
    {
        "KOD": "NO",
        "ULKE_ADI": "Norveç"
    },
    {
        "KOD": "CF",
        "ULKE_ADI": "Orta Afrika Cumhuriyeti"
    },
    {
        "KOD": "UZ",
        "ULKE_ADI": "Özbekistan"
    },
    {
        "KOD": "PK",
        "ULKE_ADI": "Pakistan"
    },
    {
        "KOD": "PW",
        "ULKE_ADI": "Palau"
    },
    {
        "KOD": "PA",
        "ULKE_ADI": "Panama"
    },
    {
        "KOD": "PG",
        "ULKE_ADI": "Papua Yeni Gine"
    },
    {
        "KOD": "PY",
        "ULKE_ADI": "Paraguay"
    },
    {
        "KOD": "PE",
        "ULKE_ADI": "Peru"
    },
    {
        "KOD": "PN",
        "ULKE_ADI": "Pitcairn"
    },
    {
        "KOD": "PL",
        "ULKE_ADI": "Polonya"
    },
    {
        "KOD": "PT",
        "ULKE_ADI": "Portekiz"
    },
    {
        "KOD": "RE",
        "ULKE_ADI": "Reunion"
    },
    {
        "KOD": "RO",
        "ULKE_ADI": "Romanya"
    },
    {
        "KOD": "RW",
        "ULKE_ADI": "Ruanda"
    },
    {
        "KOD": "RU",
        "ULKE_ADI": "Rusya Federasyonu"
    },
    {
        "KOD": "WS",
        "ULKE_ADI": "Samoa"
    },
    {
        "KOD": "SM",
        "ULKE_ADI": "San Marino"
    },
    {
        "KOD": "ST",
        "ULKE_ADI": "Sao Tome And Prıncıpe"
    },
    {
        "KOD": "SN",
        "ULKE_ADI": "Senegal"
    },
    {
        "KOD": "SP",
        "ULKE_ADI": "Septe"
    },
    {
        "KOD": "SAB",
        "ULKE_ADI": "Seyşel Adaları Ve Bağlantıları"
    },
    {
        "KOD": "SL",
        "ULKE_ADI": "Sıerra Leone"
    },
    {
        "KOD": "RS",
        "ULKE_ADI": "Sırbistan"
    },
    {
        "KOD": "SG",
        "ULKE_ADI": "Singapur"
    },
    {
        "KOD": "SK",
        "ULKE_ADI": "Slovakya"
    },
    {
        "KOD": "SI",
        "ULKE_ADI": "Slovenya"
    },
    {
        "KOD": "SB",
        "ULKE_ADI": "Solomon Adaları"
    },
    {
        "KOD": "SO",
        "ULKE_ADI": "Somali"
    },
    {
        "KOD": "LK",
        "ULKE_ADI": "Sri Lanka"
    },
    {
        "KOD": "HB",
        "ULKE_ADI": "St. Helena Ve Bağlantıları"
    },
    {
        "KOD": "LC",
        "ULKE_ADI": "St. Lucia"
    },
    {
        "KOD": "PM",
        "ULKE_ADI": "St. Pierre Ve Miquelon"
    },
    {
        "KOD": "VC",
        "ULKE_ADI": "St Vincent Ve Grenadinler"
    },
    {
        "KOD": "KN",
        "ULKE_ADI": "St.Kıtts   Nevis"
    },
    {
        "KOD": "SD",
        "ULKE_ADI": "Sudan"
    },
    {
        "KOD": "SR",
        "ULKE_ADI": "Surinam"
    },
    {
        "KOD": "SY",
        "ULKE_ADI": "Suriye"
    },
    {
        "KOD": "SA",
        "ULKE_ADI": "Suudi Arabistan"
    },
    {
        "KOD": "CL",
        "ULKE_ADI": "Şili"
    },
    {
        "KOD": "TJ",
        "ULKE_ADI": "Tacikistan"
    },
    {
        "KOD": "TZ",
        "ULKE_ADI": "Tanzanya"
    },
    {
        "KOD": "TS",
        "ULKE_ADI": "Tataristan"
    },
    {
        "KOD": "TH",
        "ULKE_ADI": "Tayland"
    },
    {
        "KOD": "TW",
        "ULKE_ADI": "Tayvan"
    },
    {
        "KOD": "TG",
        "ULKE_ADI": "Togo"
    },
    {
        "KOD": "TK",
        "ULKE_ADI": "Tokelau"
    },
    {
        "KOD": "TO",
        "ULKE_ADI": "Tonga"
    },
    {
        "KOD": "TT",
        "ULKE_ADI": "Trinidad Ve Tobago"
    },
    {
        "KOD": "TN",
        "ULKE_ADI": "Tunus"
    },
    {
        "KOD": "TC",
        "ULKE_ADI": "Turks Ve Caicos Adası"
    },
    {
        "KOD": "TV",
        "ULKE_ADI": "Tuvalu"
    },
    {
        "KOD": "TM",
        "ULKE_ADI": "Türkmenistan"
    },
    {
        "KOD": "UG",
        "ULKE_ADI": "Uganda"
    },
    {
        "KOD": "UA",
        "ULKE_ADI": "Ukrayna"
    },
    {
        "KOD": "OM",
        "ULKE_ADI": "Umman"
    },
    {
        "KOD": "UY",
        "ULKE_ADI": "Uruguay"
    },
    {
        "KOD": "JO",
        "ULKE_ADI": "Ürdün"
    },
    {
        "KOD": "VU",
        "ULKE_ADI": "Vanuatu"
    },
    {
        "KOD": "VA",
        "ULKE_ADI": "Vatikan"
    },
    {
        "KOD": "VE",
        "ULKE_ADI": "Venezuella"
    },
    {
        "KOD": "VN",
        "ULKE_ADI": "Vietnam"
    },
    {
        "KOD": "VI",
        "ULKE_ADI": "Virgin Adaları"
    },
    {
        "KOD": "WF",
        "ULKE_ADI": "Wallis Ve Futuna Adaları"
    },
    {
        "KOD": "YK",
        "ULKE_ADI": "Yakutistan"
    },
    {
        "KOD": "YE",
        "ULKE_ADI": "Yemen"
    },
    {
        "KOD": "NC",
        "ULKE_ADI": "Yeni Kalodenya Ve Bağlantıları"
    },
    {
        "KOD": "NZ",
        "ULKE_ADI": "Yeni Zelanda"
    },
    {
        "KOD": "YU",
        "ULKE_ADI": "Yugoslavya"
    },
    {
        "KOD": "GR",
        "ULKE_ADI": "Yunanistan"
    },
    {
        "KOD": "CD",
        "ULKE_ADI": "Zaire"
    },
    {
        "KOD": "ZM",
        "ULKE_ADI": "Zambia"
    },
    {
        "KOD": "ZW",
        "ULKE_ADI": "Zimbabve"
    }
]
export const provinces = [
    {
        "KODU": "99133",
        "IL_KODU": 1,
        "AD": "Şakirpaşa Havalimanı",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "2033",
        "IL_KODU": 1,
        "AD": "ÇUKUROVA",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "2032",
        "IL_KODU": 1,
        "AD": "SARIÇAM",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1806",
        "IL_KODU": 1,
        "AD": "İMAMOĞLU",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1757",
        "IL_KODU": 1,
        "AD": "ALADAĞ",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1748",
        "IL_KODU": 1,
        "AD": "YÜREĞİR",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1734",
        "IL_KODU": 1,
        "AD": "YUMURTALIK",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1687",
        "IL_KODU": 1,
        "AD": "TUFANBEYLİ",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1588",
        "IL_KODU": 1,
        "AD": "SAİMBEYLİ",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1580",
        "IL_KODU": 1,
        "AD": "POZANTI",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1486",
        "IL_KODU": 1,
        "AD": "KOZAN",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1443",
        "IL_KODU": 1,
        "AD": "KARATAŞ",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1437",
        "IL_KODU": 1,
        "AD": "KARAİSALI",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1329",
        "IL_KODU": 1,
        "AD": "FEKE",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1219",
        "IL_KODU": 1,
        "AD": "CEYHAN",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "1104",
        "IL_KODU": 1,
        "AD": "SEYHAN",
        "IL_ADI": "ADANA"
    },
    {
        "KODU": "99139",
        "IL_KODU": 2,
        "AD": "Adıyaman Havalimanı",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1989",
        "IL_KODU": 2,
        "AD": "TUT",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1985",
        "IL_KODU": 2,
        "AD": "SİNCİK",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1592",
        "IL_KODU": 2,
        "AD": "SAMSAT",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1425",
        "IL_KODU": 2,
        "AD": "KAHTA",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1354",
        "IL_KODU": 2,
        "AD": "GÖLBAŞI",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1347",
        "IL_KODU": 2,
        "AD": "GERGER",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1246",
        "IL_KODU": 2,
        "AD": "ÇELİKHAN",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1182",
        "IL_KODU": 2,
        "AD": "BESNİ",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "1105",
        "IL_KODU": 2,
        "AD": "MERKEZ",
        "IL_ADI": "ADIYAMAN"
    },
    {
        "KODU": "99132",
        "IL_KODU": 3,
        "AD": "Afyon Havalimanı",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1961",
        "IL_KODU": 3,
        "AD": "KIZILÖREN",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1944",
        "IL_KODU": 3,
        "AD": "HOCALAR",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1923",
        "IL_KODU": 3,
        "AD": "EVCİLER",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1906",
        "IL_KODU": 3,
        "AD": "ÇOBANLAR",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1809",
        "IL_KODU": 3,
        "AD": "İSCEHİSAR",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1773",
        "IL_KODU": 3,
        "AD": "BAYAT",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1771",
        "IL_KODU": 3,
        "AD": "BAŞMAKÇI",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1664",
        "IL_KODU": 3,
        "AD": "ŞUHUT",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1639",
        "IL_KODU": 3,
        "AD": "SULTANDAĞI",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1626",
        "IL_KODU": 3,
        "AD": "SİNANPAŞA",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1594",
        "IL_KODU": 3,
        "AD": "SANDIKLI",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1404",
        "IL_KODU": 3,
        "AD": "İHSANİYE",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1306",
        "IL_KODU": 3,
        "AD": "EMİRDAĞ",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1281",
        "IL_KODU": 3,
        "AD": "DİNAR",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1267",
        "IL_KODU": 3,
        "AD": "DAZKIRI",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1239",
        "IL_KODU": 3,
        "AD": "ÇAY",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1200",
        "IL_KODU": 3,
        "AD": "BOLVADİN",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "1108",
        "IL_KODU": 3,
        "AD": "MERKEZ",
        "IL_ADI": "AFYONKARAHİSAR"
    },
    {
        "KODU": "99140",
        "IL_KODU": 4,
        "AD": "Ağrı Ahmed-i Hani Havalimanı",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1691",
        "IL_KODU": 4,
        "AD": "TUTAK",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1667",
        "IL_KODU": 4,
        "AD": "TAŞLIÇAY",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1568",
        "IL_KODU": 4,
        "AD": "PATNOS",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1379",
        "IL_KODU": 4,
        "AD": "HAMUR",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1301",
        "IL_KODU": 4,
        "AD": "ELEŞKİRT",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1287",
        "IL_KODU": 4,
        "AD": "DOĞUBAYAZIT",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1283",
        "IL_KODU": 4,
        "AD": "DİYADİN",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "1111",
        "IL_KODU": 4,
        "AD": "MERKEZ",
        "IL_ADI": "AĞRI"
    },
    {
        "KODU": "99166",
        "IL_KODU": 5,
        "AD": "Amasya Merzifon Havalimanı",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1938",
        "IL_KODU": 5,
        "AD": "HAMAMÖZÜ",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1668",
        "IL_KODU": 5,
        "AD": "TAŞOVA",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1641",
        "IL_KODU": 5,
        "AD": "SULUOVA",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1524",
        "IL_KODU": 5,
        "AD": "MERZİFON",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1368",
        "IL_KODU": 5,
        "AD": "GÜMÜŞHACIKÖY",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1363",
        "IL_KODU": 5,
        "AD": "GÖYNÜCEK",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "1134",
        "IL_KODU": 5,
        "AD": "MERKEZ",
        "IL_ADI": "AMASYA"
    },
    {
        "KODU": "99134",
        "IL_KODU": 6,
        "AD": "Ankara Esenboğa Havalimanı",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "2034",
        "IL_KODU": 6,
        "AD": "PURSAKLAR",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1924",
        "IL_KODU": 6,
        "AD": "EVREN",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1922",
        "IL_KODU": 6,
        "AD": "ETİMESGUT",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1872",
        "IL_KODU": 6,
        "AD": "AKYURT",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1815",
        "IL_KODU": 6,
        "AD": "KAHRAMANKAZAN",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1747",
        "IL_KODU": 6,
        "AD": "SİNCAN",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1746",
        "IL_KODU": 6,
        "AD": "MAMAK",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1745",
        "IL_KODU": 6,
        "AD": "KEÇİÖREN",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1744",
        "IL_KODU": 6,
        "AD": "GÖLBAŞI",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1723",
        "IL_KODU": 6,
        "AD": "YENİMAHALLE",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1658",
        "IL_KODU": 6,
        "AD": "ŞEREFLİKOÇHİSAR",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1578",
        "IL_KODU": 6,
        "AD": "POLATLI",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1539",
        "IL_KODU": 6,
        "AD": "NALLIHAN",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1473",
        "IL_KODU": 6,
        "AD": "KIZILCAHAMAM",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1427",
        "IL_KODU": 6,
        "AD": "KALECİK",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1387",
        "IL_KODU": 6,
        "AD": "HAYMANA",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1365",
        "IL_KODU": 6,
        "AD": "GÜDÜL",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1302",
        "IL_KODU": 6,
        "AD": "ELMADAĞ",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1260",
        "IL_KODU": 6,
        "AD": "ÇUBUK",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1231",
        "IL_KODU": 6,
        "AD": "ÇANKAYA",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1227",
        "IL_KODU": 6,
        "AD": "ÇAMLIDERE",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1187",
        "IL_KODU": 6,
        "AD": "BEYPAZARI",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1167",
        "IL_KODU": 6,
        "AD": "BALA",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1157",
        "IL_KODU": 6,
        "AD": "AYAŞ",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "1130",
        "IL_KODU": 6,
        "AD": "ALTINDAĞ",
        "IL_ADI": "ANKARA"
    },
    {
        "KODU": "99141",
        "IL_KODU": 7,
        "AD": "Gazipaşa Havalimanı",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "99135",
        "IL_KODU": 7,
        "AD": "Antalya Havalimanı",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "2039",
        "IL_KODU": 7,
        "AD": "MURATPAŞA",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "2038",
        "IL_KODU": 7,
        "AD": "KONYAALTI",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "2037",
        "IL_KODU": 7,
        "AD": "KEPEZ",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "2036",
        "IL_KODU": 7,
        "AD": "DÖŞEMEALTI",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "2035",
        "IL_KODU": 7,
        "AD": "AKSU",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1959",
        "IL_KODU": 7,
        "AD": "KEMER",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1946",
        "IL_KODU": 7,
        "AD": "İBRADI",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1811",
        "IL_KODU": 7,
        "AD": "DEMRE",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1616",
        "IL_KODU": 7,
        "AD": "SERİK",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1512",
        "IL_KODU": 7,
        "AD": "MANAVGAT",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1492",
        "IL_KODU": 7,
        "AD": "KUMLUCA",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1483",
        "IL_KODU": 7,
        "AD": "KORKUTELİ",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1451",
        "IL_KODU": 7,
        "AD": "KAŞ",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1370",
        "IL_KODU": 7,
        "AD": "GÜNDOĞMUŞ",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1337",
        "IL_KODU": 7,
        "AD": "GAZİPAŞA",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1333",
        "IL_KODU": 7,
        "AD": "FİNİKE",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1303",
        "IL_KODU": 7,
        "AD": "ELMALI",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1138",
        "IL_KODU": 7,
        "AD": "MERKEZ",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1126",
        "IL_KODU": 7,
        "AD": "ALANYA",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "1121",
        "IL_KODU": 7,
        "AD": "AKSEKİ",
        "IL_ADI": "ANTALYA"
    },
    {
        "KODU": "2105",
        "IL_KODU": 8,
        "AD": "KEMALPAŞA",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1828",
        "IL_KODU": 8,
        "AD": "MURGUL",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1736",
        "IL_KODU": 8,
        "AD": "YUSUFELİ",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1653",
        "IL_KODU": 8,
        "AD": "ŞAVŞAT",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1395",
        "IL_KODU": 8,
        "AD": "HOPA",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1202",
        "IL_KODU": 8,
        "AD": "BORÇKA",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1152",
        "IL_KODU": 8,
        "AD": "MERKEZ",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1147",
        "IL_KODU": 8,
        "AD": "ARHAVİ",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "1145",
        "IL_KODU": 8,
        "AD": "ARDANUÇ",
        "IL_ADI": "ARTVİN"
    },
    {
        "KODU": "2076",
        "IL_KODU": 9,
        "AD": "EFELER",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "2000",
        "IL_KODU": 9,
        "AD": "DİDİM",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1968",
        "IL_KODU": 9,
        "AD": "KÖŞK",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1957",
        "IL_KODU": 9,
        "AD": "KARPUZLU",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1807",
        "IL_KODU": 9,
        "AD": "İNCİRLİOVA",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1781",
        "IL_KODU": 9,
        "AD": "BUHARKENT",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1724",
        "IL_KODU": 9,
        "AD": "YENİPAZAR",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1640",
        "IL_KODU": 9,
        "AD": "SULTANHİSAR",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1637",
        "IL_KODU": 9,
        "AD": "SÖKE",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1542",
        "IL_KODU": 9,
        "AD": "NAZİLLİ",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1498",
        "IL_KODU": 9,
        "AD": "KUYUCAK",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1497",
        "IL_KODU": 9,
        "AD": "KUŞADASI",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1479",
        "IL_KODU": 9,
        "AD": "KOÇARLI",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1435",
        "IL_KODU": 9,
        "AD": "KARACASU",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1348",
        "IL_KODU": 9,
        "AD": "GERMENCİK",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1256",
        "IL_KODU": 9,
        "AD": "ÇİNE",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1206",
        "IL_KODU": 9,
        "AD": "BOZDOĞAN",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "1159",
        "IL_KODU": 9,
        "AD": "MERKEZ",
        "IL_ADI": "AYDIN"
    },
    {
        "KODU": "99142",
        "IL_KODU": 10,
        "AD": "Balıkesir Merkez Havalimanı",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "99107",
        "IL_KODU": 10,
        "AD": "Balıkesir Koca Seyit Havalimanı",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "2078",
        "IL_KODU": 10,
        "AD": "KARESİ",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "2077",
        "IL_KODU": 10,
        "AD": "ALTIEYLÜL",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1928",
        "IL_KODU": 10,
        "AD": "GÖMEÇ",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1824",
        "IL_KODU": 10,
        "AD": "MARMARA",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1644",
        "IL_KODU": 10,
        "AD": "SUSURLUK",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1619",
        "IL_KODU": 10,
        "AD": "SINDIRGI",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1608",
        "IL_KODU": 10,
        "AD": "SAVAŞTEPE",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1514",
        "IL_KODU": 10,
        "AD": "MANYAS",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1462",
        "IL_KODU": 10,
        "AD": "KEPSUT",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1418",
        "IL_KODU": 10,
        "AD": "İVRİNDİ",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1384",
        "IL_KODU": 10,
        "AD": "HAVRAN",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1360",
        "IL_KODU": 10,
        "AD": "GÖNEN",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1310",
        "IL_KODU": 10,
        "AD": "ERDEK",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1294",
        "IL_KODU": 10,
        "AD": "EDREMİT",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1291",
        "IL_KODU": 10,
        "AD": "DURSUNBEY",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1216",
        "IL_KODU": 10,
        "AD": "BURHANİYE",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1191",
        "IL_KODU": 10,
        "AD": "BİGADİÇ",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1171",
        "IL_KODU": 10,
        "AD": "BANDIRMA",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1169",
        "IL_KODU": 10,
        "AD": "BALYA",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1168",
        "IL_KODU": 10,
        "AD": "MERKEZ",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1161",
        "IL_KODU": 10,
        "AD": "AYVALIK",
        "IL_ADI": "BALIKESİR"
    },
    {
        "KODU": "1948",
        "IL_KODU": 11,
        "AD": "İNHİSAR",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1857",
        "IL_KODU": 11,
        "AD": "YENİPAZAR",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1636",
        "IL_KODU": 11,
        "AD": "SÖĞÜT",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1571",
        "IL_KODU": 11,
        "AD": "PAZARYERİ",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1559",
        "IL_KODU": 11,
        "AD": "OSMANELİ",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1359",
        "IL_KODU": 11,
        "AD": "GÖLPAZARI",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1210",
        "IL_KODU": 11,
        "AD": "BOZÜYÜK",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "1192",
        "IL_KODU": 11,
        "AD": "MERKEZ",
        "IL_ADI": "BİLECİK"
    },
    {
        "KODU": "99167",
        "IL_KODU": 12,
        "AD": "Bingöl Havalimanı",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1996",
        "IL_KODU": 12,
        "AD": "YEDİSU",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1855",
        "IL_KODU": 12,
        "AD": "YAYLADERE",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1750",
        "IL_KODU": 12,
        "AD": "ADAKLI",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1633",
        "IL_KODU": 12,
        "AD": "SOLHAN",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1475",
        "IL_KODU": 12,
        "AD": "KİĞI",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1446",
        "IL_KODU": 12,
        "AD": "KARLIOVA",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1344",
        "IL_KODU": 12,
        "AD": "GENÇ",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1193",
        "IL_KODU": 12,
        "AD": "MERKEZ",
        "IL_ADI": "BİNGÖL"
    },
    {
        "KODU": "1798",
        "IL_KODU": 13,
        "AD": "GÜROYMAK",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1669",
        "IL_KODU": 13,
        "AD": "TATVAN",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1537",
        "IL_KODU": 13,
        "AD": "MUTKİ",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1394",
        "IL_KODU": 13,
        "AD": "HİZAN",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1196",
        "IL_KODU": 13,
        "AD": "MERKEZ",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1112",
        "IL_KODU": 13,
        "AD": "AHLAT",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1106",
        "IL_KODU": 13,
        "AD": "ADİLCEVAZ",
        "IL_ADI": "BİTLİS"
    },
    {
        "KODU": "1997",
        "IL_KODU": 14,
        "AD": "YENİÇAĞA",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1916",
        "IL_KODU": 14,
        "AD": "DÖRTDİVAN",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1610",
        "IL_KODU": 14,
        "AD": "SEBEN",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1531",
        "IL_KODU": 14,
        "AD": "MUDURNU",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1522",
        "IL_KODU": 14,
        "AD": "MENGEN",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1466",
        "IL_KODU": 14,
        "AD": "KIBRISCIK",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1364",
        "IL_KODU": 14,
        "AD": "GÖYNÜK",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1346",
        "IL_KODU": 14,
        "AD": "GEREDE",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1199",
        "IL_KODU": 14,
        "AD": "MERKEZ",
        "IL_ADI": "BOLU"
    },
    {
        "KODU": "1903",
        "IL_KODU": 15,
        "AD": "ÇELTİKÇİ",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1899",
        "IL_KODU": 15,
        "AD": "ÇAVDIR",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1874",
        "IL_KODU": 15,
        "AD": "ALTINYAYLA",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1816",
        "IL_KODU": 15,
        "AD": "KEMER",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1813",
        "IL_KODU": 15,
        "AD": "KARAMANLI",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1728",
        "IL_KODU": 15,
        "AD": "YEŞİLOVA",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1672",
        "IL_KODU": 15,
        "AD": "TEFENNİ",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1357",
        "IL_KODU": 15,
        "AD": "GÖLHİSAR",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1215",
        "IL_KODU": 15,
        "AD": "MERKEZ",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1211",
        "IL_KODU": 15,
        "AD": "BUCAK",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "1109",
        "IL_KODU": 15,
        "AD": "AĞLASUN",
        "IL_ADI": "BURDUR"
    },
    {
        "KODU": "99124",
        "IL_KODU": 16,
        "AD": "Bursa Yenişehir Havalimanı",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1960",
        "IL_KODU": 16,
        "AD": "KESTEL",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1935",
        "IL_KODU": 16,
        "AD": "GÜRSU",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1859",
        "IL_KODU": 16,
        "AD": "YILDIRIM",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1832",
        "IL_KODU": 16,
        "AD": "OSMANGAZİ",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1829",
        "IL_KODU": 16,
        "AD": "NİLÜFER",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1799",
        "IL_KODU": 16,
        "AD": "HARMANCIK",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1783",
        "IL_KODU": 16,
        "AD": "BÜYÜKORHAN",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1725",
        "IL_KODU": 16,
        "AD": "YENİŞEHİR",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1554",
        "IL_KODU": 16,
        "AD": "ORHANGAZİ",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1553",
        "IL_KODU": 16,
        "AD": "ORHANELİ",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1535",
        "IL_KODU": 16,
        "AD": "MUSTAFAKEMALPAŞA",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1530",
        "IL_KODU": 16,
        "AD": "MUDANYA",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1457",
        "IL_KODU": 16,
        "AD": "KELES",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1434",
        "IL_KODU": 16,
        "AD": "KARACABEY",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1420",
        "IL_KODU": 16,
        "AD": "İZNİK",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1411",
        "IL_KODU": 16,
        "AD": "İNEGÖL",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "1343",
        "IL_KODU": 16,
        "AD": "GEMLİK",
        "IL_ADI": "BURSA"
    },
    {
        "KODU": "99168",
        "IL_KODU": 17,
        "AD": "Çanakkale Havalimanı",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "99128",
        "IL_KODU": 17,
        "AD": "Gökçeada Havalimanı",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1722",
        "IL_KODU": 17,
        "AD": "YENİCE",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1503",
        "IL_KODU": 17,
        "AD": "LAPSEKİ",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1408",
        "IL_KODU": 17,
        "AD": "GÖKÇEADA",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1340",
        "IL_KODU": 17,
        "AD": "GELİBOLU",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1326",
        "IL_KODU": 17,
        "AD": "EZİNE",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1293",
        "IL_KODU": 17,
        "AD": "ECEABAT",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1230",
        "IL_KODU": 17,
        "AD": "MERKEZ",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1229",
        "IL_KODU": 17,
        "AD": "ÇAN",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1205",
        "IL_KODU": 17,
        "AD": "BOZCAADA",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1190",
        "IL_KODU": 17,
        "AD": "BİGA",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1180",
        "IL_KODU": 17,
        "AD": "BAYRAMİÇ",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1160",
        "IL_KODU": 17,
        "AD": "AYVACIK",
        "IL_ADI": "ÇANAKKALE"
    },
    {
        "KODU": "1963",
        "IL_KODU": 18,
        "AD": "KORGUN",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1885",
        "IL_KODU": 18,
        "AD": "BAYRAMÖREN",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1817",
        "IL_KODU": 18,
        "AD": "KIZILIRMAK",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1765",
        "IL_KODU": 18,
        "AD": "ATKARACALAR",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1718",
        "IL_KODU": 18,
        "AD": "YAPRAKLI",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1649",
        "IL_KODU": 18,
        "AD": "ŞABANÖZÜ",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1555",
        "IL_KODU": 18,
        "AD": "ORTA",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1494",
        "IL_KODU": 18,
        "AD": "KURŞUNLU",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1399",
        "IL_KODU": 18,
        "AD": "ILGAZ",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1300",
        "IL_KODU": 18,
        "AD": "ELDİVAN",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1248",
        "IL_KODU": 18,
        "AD": "ÇERKEŞ",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1232",
        "IL_KODU": 18,
        "AD": "MERKEZ",
        "IL_ADI": "ÇANKIRI"
    },
    {
        "KODU": "1976",
        "IL_KODU": 19,
        "AD": "OĞUZLAR",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1972",
        "IL_KODU": 19,
        "AD": "LAÇİN",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1911",
        "IL_KODU": 19,
        "AD": "DODURGA",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1850",
        "IL_KODU": 19,
        "AD": "UĞURLUDAĞ",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1778",
        "IL_KODU": 19,
        "AD": "BOĞAZKALE",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1642",
        "IL_KODU": 19,
        "AD": "SUNGURLU",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1558",
        "IL_KODU": 19,
        "AD": "OSMANCIK",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1556",
        "IL_KODU": 19,
        "AD": "ORTAKÖY",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1520",
        "IL_KODU": 19,
        "AD": "MECİTÖZÜ",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1445",
        "IL_KODU": 19,
        "AD": "KARGI",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1414",
        "IL_KODU": 19,
        "AD": "İSKİLİP",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1259",
        "IL_KODU": 19,
        "AD": "MERKEZ",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1177",
        "IL_KODU": 19,
        "AD": "BAYAT",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "1124",
        "IL_KODU": 19,
        "AD": "ALACA",
        "IL_ADI": "ÇORUM"
    },
    {
        "KODU": "99143",
        "IL_KODU": 20,
        "AD": "Denizli Çardak Havalimanı",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "2079",
        "IL_KODU": 20,
        "AD": "MERKEZEFENDİ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1889",
        "IL_KODU": 20,
        "AD": "BOZKURT",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1888",
        "IL_KODU": 20,
        "AD": "BEYAĞAÇ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1881",
        "IL_KODU": 20,
        "AD": "BAKLAN",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1871",
        "IL_KODU": 20,
        "AD": "PAMUKKALE",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1840",
        "IL_KODU": 20,
        "AD": "SERİNHİSAR",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1803",
        "IL_KODU": 20,
        "AD": "HONAZ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1774",
        "IL_KODU": 20,
        "AD": "BEKİLLİ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1769",
        "IL_KODU": 20,
        "AD": "BABADAĞ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1670",
        "IL_KODU": 20,
        "AD": "TAVAS",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1597",
        "IL_KODU": 20,
        "AD": "SARAYKÖY",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1426",
        "IL_KODU": 20,
        "AD": "KALE",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1371",
        "IL_KODU": 20,
        "AD": "GÜNEY",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1271",
        "IL_KODU": 20,
        "AD": "MERKEZ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1257",
        "IL_KODU": 20,
        "AD": "ÇİVRİL",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1233",
        "IL_KODU": 20,
        "AD": "ÇARDAK",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1226",
        "IL_KODU": 20,
        "AD": "ÇAMELİ",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1224",
        "IL_KODU": 20,
        "AD": "ÇAL",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1214",
        "IL_KODU": 20,
        "AD": "BULDAN",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "1102",
        "IL_KODU": 20,
        "AD": "ACIPAYAM",
        "IL_ADI": "DENİZLİ"
    },
    {
        "KODU": "99126",
        "IL_KODU": 21,
        "AD": "Diyarbakır Havalimanı",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "2043",
        "IL_KODU": 21,
        "AD": "YENİŞEHİR",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "2042",
        "IL_KODU": 21,
        "AD": "SUR",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "2041",
        "IL_KODU": 21,
        "AD": "KAYAPINAR",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "2040",
        "IL_KODU": 21,
        "AD": "BAĞLAR",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1962",
        "IL_KODU": 21,
        "AD": "KOCAKÖY",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1791",
        "IL_KODU": 21,
        "AD": "EĞİL",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1624",
        "IL_KODU": 21,
        "AD": "SİLVAN",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1504",
        "IL_KODU": 21,
        "AD": "LİCE",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1490",
        "IL_KODU": 21,
        "AD": "KULP",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1389",
        "IL_KODU": 21,
        "AD": "HAZRO",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1381",
        "IL_KODU": 21,
        "AD": "HANİ",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1315",
        "IL_KODU": 21,
        "AD": "ERGANİ",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1284",
        "IL_KODU": 21,
        "AD": "MERKEZ",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1278",
        "IL_KODU": 21,
        "AD": "DİCLE",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1263",
        "IL_KODU": 21,
        "AD": "ÇÜNGÜŞ",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1253",
        "IL_KODU": 21,
        "AD": "ÇINAR",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1249",
        "IL_KODU": 21,
        "AD": "ÇERMİK",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1195",
        "IL_KODU": 21,
        "AD": "BİSMİL",
        "IL_ADI": "DİYARBAKIR"
    },
    {
        "KODU": "1988",
        "IL_KODU": 22,
        "AD": "SÜLOĞLU",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1705",
        "IL_KODU": 22,
        "AD": "UZUNKÖPRÜ",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1523",
        "IL_KODU": 22,
        "AD": "MERİÇ",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1502",
        "IL_KODU": 22,
        "AD": "LALAPAŞA",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1464",
        "IL_KODU": 22,
        "AD": "KEŞAN",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1412",
        "IL_KODU": 22,
        "AD": "İPSALA",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1385",
        "IL_KODU": 22,
        "AD": "HAVSA",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1307",
        "IL_KODU": 22,
        "AD": "ENEZ",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "1295",
        "IL_KODU": 22,
        "AD": "MERKEZ",
        "IL_ADI": "EDİRNE"
    },
    {
        "KODU": "99144",
        "IL_KODU": 23,
        "AD": "Elazığ Havalimanı",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1873",
        "IL_KODU": 23,
        "AD": "ALACAKAYA",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1820",
        "IL_KODU": 23,
        "AD": "KOVANCILAR",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1762",
        "IL_KODU": 23,
        "AD": "ARICAK",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1631",
        "IL_KODU": 23,
        "AD": "SİVRİCE",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1566",
        "IL_KODU": 23,
        "AD": "PALU",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1506",
        "IL_KODU": 23,
        "AD": "MADEN",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1455",
        "IL_KODU": 23,
        "AD": "KEBAN",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1438",
        "IL_KODU": 23,
        "AD": "KARAKOÇAN",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1298",
        "IL_KODU": 23,
        "AD": "MERKEZ",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1173",
        "IL_KODU": 23,
        "AD": "BASKİL",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "1110",
        "IL_KODU": 23,
        "AD": "AĞIN",
        "IL_ADI": "ELAZIĞ"
    },
    {
        "KODU": "99109",
        "IL_KODU": 24,
        "AD": "Erzincan Havalimanı",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1977",
        "IL_KODU": 24,
        "AD": "OTLUKBELİ",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1853",
        "IL_KODU": 24,
        "AD": "ÜZÜMLÜ",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1675",
        "IL_KODU": 24,
        "AD": "TERCAN",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1583",
        "IL_KODU": 24,
        "AD": "REFAHİYE",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1460",
        "IL_KODU": 24,
        "AD": "KEMALİYE",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1459",
        "IL_KODU": 24,
        "AD": "KEMAH",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1406",
        "IL_KODU": 24,
        "AD": "İLİÇ",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1318",
        "IL_KODU": 24,
        "AD": "MERKEZ",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "1243",
        "IL_KODU": 24,
        "AD": "ÇAYIRLI",
        "IL_ADI": "ERZİNCAN"
    },
    {
        "KODU": "99100",
        "IL_KODU": 25,
        "AD": "Erzurum Havalimanı",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "2045",
        "IL_KODU": 25,
        "AD": "YAKUTİYE",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "2044",
        "IL_KODU": 25,
        "AD": "PALANDÖKEN",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1967",
        "IL_KODU": 25,
        "AD": "KÖPRÜKÖY",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1945",
        "IL_KODU": 25,
        "AD": "AZİZİYE",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1865",
        "IL_KODU": 25,
        "AD": "PAZARYOLU",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1851",
        "IL_KODU": 25,
        "AD": "UZUNDERE",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1812",
        "IL_KODU": 25,
        "AD": "KARAÇOBAN",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1683",
        "IL_KODU": 25,
        "AD": "TORTUM",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1674",
        "IL_KODU": 25,
        "AD": "TEKMAN",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1657",
        "IL_KODU": 25,
        "AD": "ŞENKAYA",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1567",
        "IL_KODU": 25,
        "AD": "PASİNLER",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1551",
        "IL_KODU": 25,
        "AD": "OLUR",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1550",
        "IL_KODU": 25,
        "AD": "OLTU",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1540",
        "IL_KODU": 25,
        "AD": "NARMAN",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1444",
        "IL_KODU": 25,
        "AD": "KARAYAZI",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1416",
        "IL_KODU": 25,
        "AD": "İSPİR",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1396",
        "IL_KODU": 25,
        "AD": "HORASAN",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1392",
        "IL_KODU": 25,
        "AD": "HINIS",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1319",
        "IL_KODU": 25,
        "AD": "MERKEZ",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1235",
        "IL_KODU": 25,
        "AD": "ÇAT",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "1153",
        "IL_KODU": 25,
        "AD": "AŞKALE",
        "IL_ADI": "ERZURUM"
    },
    {
        "KODU": "99162",
        "IL_KODU": 26,
        "AD": "Eskişehir Anadolu Havalimanı",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "2047",
        "IL_KODU": 26,
        "AD": "TEPEBAŞI",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "2046",
        "IL_KODU": 26,
        "AD": "ODUNPAZARI",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1973",
        "IL_KODU": 26,
        "AD": "MİHALGAZİ",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1939",
        "IL_KODU": 26,
        "AD": "HAN",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1934",
        "IL_KODU": 26,
        "AD": "GÜNYÜZÜ",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1808",
        "IL_KODU": 26,
        "AD": "İNÖNÜ",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1777",
        "IL_KODU": 26,
        "AD": "BEYLİKOVA",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1759",
        "IL_KODU": 26,
        "AD": "ALPU",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1632",
        "IL_KODU": 26,
        "AD": "SİVRİHİSAR",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1618",
        "IL_KODU": 26,
        "AD": "SEYİTGAZİ",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1599",
        "IL_KODU": 26,
        "AD": "SARICAKAYA",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1527",
        "IL_KODU": 26,
        "AD": "MİHALIÇÇIK",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1508",
        "IL_KODU": 26,
        "AD": "MAHMUDİYE",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1322",
        "IL_KODU": 26,
        "AD": "MERKEZ",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "1255",
        "IL_KODU": 26,
        "AD": "ÇİFTELER",
        "IL_ADI": "ESKİŞEHİR"
    },
    {
        "KODU": "99101",
        "IL_KODU": 27,
        "AD": "Gaziantep Havalimanı",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "2700",
        "IL_KODU": 27,
        "AD": "MERKEZ",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1974",
        "IL_KODU": 27,
        "AD": "NURDAĞI",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1956",
        "IL_KODU": 27,
        "AD": "KARKAMIŞ",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1844",
        "IL_KODU": 27,
        "AD": "ŞEHİTKAMİL",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1841",
        "IL_KODU": 27,
        "AD": "ŞAHİNBEY",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1720",
        "IL_KODU": 27,
        "AD": "YAVUZELİ",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1549",
        "IL_KODU": 27,
        "AD": "OĞUZELİ",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1546",
        "IL_KODU": 27,
        "AD": "NİZİP",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1415",
        "IL_KODU": 27,
        "AD": "İSLAHİYE",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1139",
        "IL_KODU": 27,
        "AD": "ARABAN",
        "IL_ADI": "GAZİANTEP"
    },
    {
        "KODU": "1930",
        "IL_KODU": 28,
        "AD": "GÜCE",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1912",
        "IL_KODU": 28,
        "AD": "DOĞANKENT",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1894",
        "IL_KODU": 28,
        "AD": "ÇANAKÇI",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1893",
        "IL_KODU": 28,
        "AD": "ÇAMOLUK",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1854",
        "IL_KODU": 28,
        "AD": "YAĞLIDERE",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1837",
        "IL_KODU": 28,
        "AD": "PİRAZİZ",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1678",
        "IL_KODU": 28,
        "AD": "TİREBOLU",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1654",
        "IL_KODU": 28,
        "AD": "ŞEBİNKARAHİSAR",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1465",
        "IL_KODU": 28,
        "AD": "KEŞAP",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1361",
        "IL_KODU": 28,
        "AD": "GÖRELE",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1352",
        "IL_KODU": 28,
        "AD": "MERKEZ",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1324",
        "IL_KODU": 28,
        "AD": "EYNESİL",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1320",
        "IL_KODU": 28,
        "AD": "ESPİYE",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1272",
        "IL_KODU": 28,
        "AD": "DERELİ",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1212",
        "IL_KODU": 28,
        "AD": "BULANCAK",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1133",
        "IL_KODU": 28,
        "AD": "ALUCRA",
        "IL_ADI": "GİRESUN"
    },
    {
        "KODU": "1971",
        "IL_KODU": 29,
        "AD": "KÜRTÜN",
        "IL_ADI": "GÜMÜŞHANE"
    },
    {
        "KODU": "1822",
        "IL_KODU": 29,
        "AD": "KÖSE",
        "IL_ADI": "GÜMÜŞHANE"
    },
    {
        "KODU": "1684",
        "IL_KODU": 29,
        "AD": "TORUL",
        "IL_ADI": "GÜMÜŞHANE"
    },
    {
        "KODU": "1660",
        "IL_KODU": 29,
        "AD": "ŞİRAN",
        "IL_ADI": "GÜMÜŞHANE"
    },
    {
        "KODU": "1458",
        "IL_KODU": 29,
        "AD": "KELKİT",
        "IL_ADI": "GÜMÜŞHANE"
    },
    {
        "KODU": "1369",
        "IL_KODU": 29,
        "AD": "MERKEZ",
        "IL_ADI": "GÜMÜŞHANE"
    },
    {
        "KODU": "99110",
        "IL_KODU": 30,
        "AD": "Hakkâri Yüksekova Selahaddin Eyyubi Havalimanı",
        "IL_ADI": "HAKKARİ"
    },
    {
        "KODU": "1737",
        "IL_KODU": 30,
        "AD": "YÜKSEKOVA",
        "IL_ADI": "HAKKARİ"
    },
    {
        "KODU": "1656",
        "IL_KODU": 30,
        "AD": "ŞEMDİNLİ",
        "IL_ADI": "HAKKARİ"
    },
    {
        "KODU": "1377",
        "IL_KODU": 30,
        "AD": "MERKEZ",
        "IL_ADI": "HAKKARİ"
    },
    {
        "KODU": "1261",
        "IL_KODU": 30,
        "AD": "ÇUKURCA",
        "IL_ADI": "HAKKARİ"
    },
    {
        "KODU": "99161",
        "IL_KODU": 31,
        "AD": "Hatay Havalimanı",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "2083",
        "IL_KODU": 31,
        "AD": "PAYAS",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "2082",
        "IL_KODU": 31,
        "AD": "DEFNE",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "2081",
        "IL_KODU": 31,
        "AD": "ARSUZ",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "2080",
        "IL_KODU": 31,
        "AD": "ANTAKYA",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1970",
        "IL_KODU": 31,
        "AD": "KUMLU",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1887",
        "IL_KODU": 31,
        "AD": "BELEN",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1792",
        "IL_KODU": 31,
        "AD": "ERZİN",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1721",
        "IL_KODU": 31,
        "AD": "YAYLADAĞI",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1591",
        "IL_KODU": 31,
        "AD": "SAMANDAĞ",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1585",
        "IL_KODU": 31,
        "AD": "REYHANLI",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1468",
        "IL_KODU": 31,
        "AD": "KIRIKHAN",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1413",
        "IL_KODU": 31,
        "AD": "İSKENDERUN",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1383",
        "IL_KODU": 31,
        "AD": "MERKEZ",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1382",
        "IL_KODU": 31,
        "AD": "HASSA",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1289",
        "IL_KODU": 31,
        "AD": "DÖRTYOL",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "1131",
        "IL_KODU": 31,
        "AD": "ALTINÖZÜ",
        "IL_ADI": "HATAY"
    },
    {
        "KODU": "99112",
        "IL_KODU": 32,
        "AD": "Isparta Süleyman Demirel Havalimanı",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "2001",
        "IL_KODU": 32,
        "AD": "YENİŞARBADEMLİ",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1929",
        "IL_KODU": 32,
        "AD": "GÖNEN",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1755",
        "IL_KODU": 32,
        "AD": "AKSU",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1717",
        "IL_KODU": 32,
        "AD": "YALVAÇ",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1699",
        "IL_KODU": 32,
        "AD": "ULUBORLU",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1651",
        "IL_KODU": 32,
        "AD": "ŞARKİKARAAĞAÇ",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1648",
        "IL_KODU": 32,
        "AD": "SÜTÇÜLER",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1615",
        "IL_KODU": 32,
        "AD": "SENİRKENT",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1456",
        "IL_KODU": 32,
        "AD": "KEÇİBORLU",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1401",
        "IL_KODU": 32,
        "AD": "MERKEZ",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1341",
        "IL_KODU": 32,
        "AD": "GELENDOST",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1297",
        "IL_KODU": 32,
        "AD": "EĞİRDİR",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "1154",
        "IL_KODU": 32,
        "AD": "ATABEY",
        "IL_ADI": "ISPARTA"
    },
    {
        "KODU": "2067",
        "IL_KODU": 33,
        "AD": "YENİŞEHİR",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "2066",
        "IL_KODU": 33,
        "AD": "TOROSLAR",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "2065",
        "IL_KODU": 33,
        "AD": "MEZİTLİ",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "2064",
        "IL_KODU": 33,
        "AD": "AKDENİZ",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1892",
        "IL_KODU": 33,
        "AD": "ÇAMLIYAYLA",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1779",
        "IL_KODU": 33,
        "AD": "BOZYAZI",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1766",
        "IL_KODU": 33,
        "AD": "AYDINCIK",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1665",
        "IL_KODU": 33,
        "AD": "TARSUS",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1621",
        "IL_KODU": 33,
        "AD": "SİLİFKE",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1536",
        "IL_KODU": 33,
        "AD": "MUT",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1402",
        "IL_KODU": 33,
        "AD": "MERKEZ",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1366",
        "IL_KODU": 33,
        "AD": "GÜLNAR",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1311",
        "IL_KODU": 33,
        "AD": "ERDEMLİ",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "1135",
        "IL_KODU": 33,
        "AD": "ANAMUR",
        "IL_ADI": "MERSİN"
    },
    {
        "KODU": "99157",
        "IL_KODU": 34,
        "AD": "İstanbul Havalimanı",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "99136",
        "IL_KODU": 34,
        "AD": "Atatürk Havalimanı",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "99102",
        "IL_KODU": 34,
        "AD": "Sabiha Gökçen Havalimanı",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2055",
        "IL_KODU": 34,
        "AD": "SULTANGAZİ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2054",
        "IL_KODU": 34,
        "AD": "SANCAKTEPE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2053",
        "IL_KODU": 34,
        "AD": "ESENYURT",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2052",
        "IL_KODU": 34,
        "AD": "ÇEKMEKÖY",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2051",
        "IL_KODU": 34,
        "AD": "BEYLİKDÜZÜ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2050",
        "IL_KODU": 34,
        "AD": "BAŞAKŞEHİR",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2049",
        "IL_KODU": 34,
        "AD": "ATAŞEHİR",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2048",
        "IL_KODU": 34,
        "AD": "ARNAVUTKÖY",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2016",
        "IL_KODU": 34,
        "AD": "ESENLER",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2015",
        "IL_KODU": 34,
        "AD": "TUZLA",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2014",
        "IL_KODU": 34,
        "AD": "SULTANBEYLİ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2012",
        "IL_KODU": 34,
        "AD": "MALTEPE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2010",
        "IL_KODU": 34,
        "AD": "GÜNGÖREN",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2005",
        "IL_KODU": 34,
        "AD": "BAHÇELİEVLER",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2004",
        "IL_KODU": 34,
        "AD": "BAĞCILAR",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "2003",
        "IL_KODU": 34,
        "AD": "AVCILAR",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1886",
        "IL_KODU": 34,
        "AD": "BAYRAMPAŞA",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1852",
        "IL_KODU": 34,
        "AD": "ÜMRANİYE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1835",
        "IL_KODU": 34,
        "AD": "PENDİK",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1823",
        "IL_KODU": 34,
        "AD": "KÜÇÜKÇEKMECE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1810",
        "IL_KODU": 34,
        "AD": "KAĞITHANE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1782",
        "IL_KODU": 34,
        "AD": "BÜYÜKÇEKMECE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1739",
        "IL_KODU": 34,
        "AD": "ZEYTİNBURNU",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1708",
        "IL_KODU": 34,
        "AD": "ÜSKÜDAR",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1663",
        "IL_KODU": 34,
        "AD": "ŞİŞLİ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1659",
        "IL_KODU": 34,
        "AD": "ŞİLE",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1622",
        "IL_KODU": 34,
        "AD": "SİLİVRİ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1604",
        "IL_KODU": 34,
        "AD": "SARIYER",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1449",
        "IL_KODU": 34,
        "AD": "KARTAL",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1421",
        "IL_KODU": 34,
        "AD": "KADIKÖY",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1336",
        "IL_KODU": 34,
        "AD": "GAZİOSMANPAŞA",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1327",
        "IL_KODU": 34,
        "AD": "FATİH",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1325",
        "IL_KODU": 34,
        "AD": "EYÜPSULTAN",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1305",
        "IL_KODU": 34,
        "AD": "EMİNÖNÜ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1237",
        "IL_KODU": 34,
        "AD": "ÇATALCA",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1186",
        "IL_KODU": 34,
        "AD": "BEYOĞLU",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1185",
        "IL_KODU": 34,
        "AD": "BEYKOZ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1183",
        "IL_KODU": 34,
        "AD": "BEŞİKTAŞ",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1166",
        "IL_KODU": 34,
        "AD": "BAKIRKÖY",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "1103",
        "IL_KODU": 34,
        "AD": "ADALAR",
        "IL_ADI": "İSTANBUL"
    },
    {
        "KODU": "99164",
        "IL_KODU": 35,
        "AD": "Adnan Menderes Havalimanı",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2057",
        "IL_KODU": 35,
        "AD": "KARABAĞLAR",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2056",
        "IL_KODU": 35,
        "AD": "BAYRAKLI",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2018",
        "IL_KODU": 35,
        "AD": "GÜZELBAHÇE",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2013",
        "IL_KODU": 35,
        "AD": "NARLIDERE",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2009",
        "IL_KODU": 35,
        "AD": "GAZİEMİR",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2007",
        "IL_KODU": 35,
        "AD": "ÇİĞLİ",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "2006",
        "IL_KODU": 35,
        "AD": "BALÇOVA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1826",
        "IL_KODU": 35,
        "AD": "MENDERES",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1819",
        "IL_KODU": 35,
        "AD": "KONAK",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1780",
        "IL_KODU": 35,
        "AD": "BUCA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1776",
        "IL_KODU": 35,
        "AD": "BEYDAĞ",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1703",
        "IL_KODU": 35,
        "AD": "URLA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1682",
        "IL_KODU": 35,
        "AD": "TORBALI",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1677",
        "IL_KODU": 35,
        "AD": "TİRE",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1612",
        "IL_KODU": 35,
        "AD": "SELÇUK",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1611",
        "IL_KODU": 35,
        "AD": "SEFERİHİSAR",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1563",
        "IL_KODU": 35,
        "AD": "ÖDEMİŞ",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1521",
        "IL_KODU": 35,
        "AD": "MENEMEN",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1477",
        "IL_KODU": 35,
        "AD": "KİRAZ",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1467",
        "IL_KODU": 35,
        "AD": "KINIK",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1461",
        "IL_KODU": 35,
        "AD": "KEMALPAŞA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1448",
        "IL_KODU": 35,
        "AD": "KARŞIYAKA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1432",
        "IL_KODU": 35,
        "AD": "KARABURUN",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1334",
        "IL_KODU": 35,
        "AD": "FOÇA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1280",
        "IL_KODU": 35,
        "AD": "DİKİLİ",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1251",
        "IL_KODU": 35,
        "AD": "ÇEŞME",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1203",
        "IL_KODU": 35,
        "AD": "BORNOVA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1181",
        "IL_KODU": 35,
        "AD": "BERGAMA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1178",
        "IL_KODU": 35,
        "AD": "BAYINDIR",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "1128",
        "IL_KODU": 35,
        "AD": "ALİAĞA",
        "IL_ADI": "İZMİR"
    },
    {
        "KODU": "99113",
        "IL_KODU": 36,
        "AD": "Kars Harakani Havalimanı",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1756",
        "IL_KODU": 36,
        "AD": "AKYAKA",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1645",
        "IL_KODU": 36,
        "AD": "SUSUZ",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1614",
        "IL_KODU": 36,
        "AD": "SELİM",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1601",
        "IL_KODU": 36,
        "AD": "SARIKAMIŞ",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1447",
        "IL_KODU": 36,
        "AD": "MERKEZ",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1424",
        "IL_KODU": 36,
        "AD": "KAĞIZMAN",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1279",
        "IL_KODU": 36,
        "AD": "DİGOR",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "1149",
        "IL_KODU": 36,
        "AD": "ARPAÇAY",
        "IL_ADI": "KARS"
    },
    {
        "KODU": "99154",
        "IL_KODU": 37,
        "AD": "Kastamonu Havalimanı",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1984",
        "IL_KODU": 37,
        "AD": "SEYDİLER",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1940",
        "IL_KODU": 37,
        "AD": "HANÖNÜ",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1915",
        "IL_KODU": 37,
        "AD": "DOĞANYURT",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1867",
        "IL_KODU": 37,
        "AD": "AĞLI",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1845",
        "IL_KODU": 37,
        "AD": "ŞENPAZAR",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1836",
        "IL_KODU": 37,
        "AD": "PINARBAŞI",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1805",
        "IL_KODU": 37,
        "AD": "İHSANGAZİ",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1685",
        "IL_KODU": 37,
        "AD": "TOSYA",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1666",
        "IL_KODU": 37,
        "AD": "TAŞKÖPRÜ",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1499",
        "IL_KODU": 37,
        "AD": "KÜRE",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1450",
        "IL_KODU": 37,
        "AD": "MERKEZ",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1410",
        "IL_KODU": 37,
        "AD": "İNEBOLU",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1277",
        "IL_KODU": 37,
        "AD": "DEVREKANİ",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1264",
        "IL_KODU": 37,
        "AD": "DADAY",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1238",
        "IL_KODU": 37,
        "AD": "ÇATALZEYTİN",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1221",
        "IL_KODU": 37,
        "AD": "CİDE",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1208",
        "IL_KODU": 37,
        "AD": "BOZKURT",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1162",
        "IL_KODU": 37,
        "AD": "AZDAVAY",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1140",
        "IL_KODU": 37,
        "AD": "ARAÇ",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "1101",
        "IL_KODU": 37,
        "AD": "ABANA",
        "IL_ADI": "KASTAMONU"
    },
    {
        "KODU": "99103",
        "IL_KODU": 38,
        "AD": "Erkilet Havalimanı",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1978",
        "IL_KODU": 38,
        "AD": "ÖZVATAN",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1936",
        "IL_KODU": 38,
        "AD": "HACILAR",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1864",
        "IL_KODU": 38,
        "AD": "MELİKGAZİ",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1863",
        "IL_KODU": 38,
        "AD": "KOCASİNAN",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1846",
        "IL_KODU": 38,
        "AD": "TALAS",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1752",
        "IL_KODU": 38,
        "AD": "AKKIŞLA",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1727",
        "IL_KODU": 38,
        "AD": "YEŞİLHİSAR",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1715",
        "IL_KODU": 38,
        "AD": "YAHYALI",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1680",
        "IL_KODU": 38,
        "AD": "TOMARZA",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1605",
        "IL_KODU": 38,
        "AD": "SARIZ",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1603",
        "IL_KODU": 38,
        "AD": "SARIOĞLAN",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1576",
        "IL_KODU": 38,
        "AD": "PINARBAŞI",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1409",
        "IL_KODU": 38,
        "AD": "İNCESU",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1330",
        "IL_KODU": 38,
        "AD": "FELAHİYE",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1275",
        "IL_KODU": 38,
        "AD": "DEVELİ",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1218",
        "IL_KODU": 38,
        "AD": "BÜNYAN",
        "IL_ADI": "KAYSERİ"
    },
    {
        "KODU": "1714",
        "IL_KODU": 39,
        "AD": "VİZE",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1577",
        "IL_KODU": 39,
        "AD": "PINARHİSAR",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1572",
        "IL_KODU": 39,
        "AD": "PEHLİVANKÖY",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1505",
        "IL_KODU": 39,
        "AD": "LÜLEBURGAZ",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1480",
        "IL_KODU": 39,
        "AD": "KOFÇAZ",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1471",
        "IL_KODU": 39,
        "AD": "MERKEZ",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1270",
        "IL_KODU": 39,
        "AD": "DEMİRKÖY",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1163",
        "IL_KODU": 39,
        "AD": "BABAESKİ",
        "IL_ADI": "KIRKLARELİ"
    },
    {
        "KODU": "1890",
        "IL_KODU": 40,
        "AD": "BOZTEPE",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "1869",
        "IL_KODU": 40,
        "AD": "AKÇAKENT",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "1754",
        "IL_KODU": 40,
        "AD": "AKPINAR",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "1529",
        "IL_KODU": 40,
        "AD": "MUCUR",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "1472",
        "IL_KODU": 40,
        "AD": "MERKEZ",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "1429",
        "IL_KODU": 40,
        "AD": "KAMAN",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "1254",
        "IL_KODU": 40,
        "AD": "ÇİÇEKDAĞI",
        "IL_ADI": "KIRŞEHİR"
    },
    {
        "KODU": "99155",
        "IL_KODU": 41,
        "AD": "Cengiz Topel Havalimanı",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2063",
        "IL_KODU": 41,
        "AD": "KARTEPE",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2062",
        "IL_KODU": 41,
        "AD": "İZMİT",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2061",
        "IL_KODU": 41,
        "AD": "DİLOVASI",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2060",
        "IL_KODU": 41,
        "AD": "DARICA",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2059",
        "IL_KODU": 41,
        "AD": "ÇAYIROVA",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2058",
        "IL_KODU": 41,
        "AD": "BAŞİSKELE",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "2030",
        "IL_KODU": 41,
        "AD": "DERİNCE",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "1821",
        "IL_KODU": 41,
        "AD": "KÖRFEZ",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "1478",
        "IL_KODU": 41,
        "AD": "MERKEZ",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "1440",
        "IL_KODU": 41,
        "AD": "KARAMÜRSEL",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "1430",
        "IL_KODU": 41,
        "AD": "KANDIRA",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "1355",
        "IL_KODU": 41,
        "AD": "GÖLCÜK",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "1338",
        "IL_KODU": 41,
        "AD": "GEBZE",
        "IL_ADI": "KOCAELİ"
    },
    {
        "KODU": "99104",
        "IL_KODU": 42,
        "AD": "Konya Havalimanı",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1994",
        "IL_KODU": 42,
        "AD": "YALIHÜYÜK",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1990",
        "IL_KODU": 42,
        "AD": "TUZLUKÇU",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1937",
        "IL_KODU": 42,
        "AD": "HALKAPINAR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1933",
        "IL_KODU": 42,
        "AD": "GÜNEYSINIR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1920",
        "IL_KODU": 42,
        "AD": "EMİRGAZİ",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1907",
        "IL_KODU": 42,
        "AD": "DERBENT",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1902",
        "IL_KODU": 42,
        "AD": "ÇELTİK",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1868",
        "IL_KODU": 42,
        "AD": "AHIRLI",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1848",
        "IL_KODU": 42,
        "AD": "TAŞKENT",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1839",
        "IL_KODU": 42,
        "AD": "SELÇUKLU",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1827",
        "IL_KODU": 42,
        "AD": "MERAM",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1814",
        "IL_KODU": 42,
        "AD": "KARATAY",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1804",
        "IL_KODU": 42,
        "AD": "HÜYÜK",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1789",
        "IL_KODU": 42,
        "AD": "DEREBUCAK",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1760",
        "IL_KODU": 42,
        "AD": "ALTINEKİN",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1753",
        "IL_KODU": 42,
        "AD": "AKÖREN",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1735",
        "IL_KODU": 42,
        "AD": "YUNAK",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1617",
        "IL_KODU": 42,
        "AD": "SEYDİŞEHİR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1598",
        "IL_KODU": 42,
        "AD": "SARAYÖNÜ",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1491",
        "IL_KODU": 42,
        "AD": "KULU",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1441",
        "IL_KODU": 42,
        "AD": "KARAPINAR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1422",
        "IL_KODU": 42,
        "AD": "KADINHANI",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1400",
        "IL_KODU": 42,
        "AD": "ILGIN",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1375",
        "IL_KODU": 42,
        "AD": "HADİM",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1312",
        "IL_KODU": 42,
        "AD": "EREĞLİ",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1285",
        "IL_KODU": 42,
        "AD": "DOĞANHİSAR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1262",
        "IL_KODU": 42,
        "AD": "ÇUMRA",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1222",
        "IL_KODU": 42,
        "AD": "CİHANBEYLİ",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1207",
        "IL_KODU": 42,
        "AD": "BOZKIR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1188",
        "IL_KODU": 42,
        "AD": "BEYŞEHİR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "1122",
        "IL_KODU": 42,
        "AD": "AKŞEHİR",
        "IL_ADI": "KONYA"
    },
    {
        "KODU": "99165",
        "IL_KODU": 43,
        "AD": "Zafer Havalimanı",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1979",
        "IL_KODU": 43,
        "AD": "PAZARLAR",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1898",
        "IL_KODU": 43,
        "AD": "ÇAVDARHİSAR",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1843",
        "IL_KODU": 43,
        "AD": "ŞAPHANE",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1802",
        "IL_KODU": 43,
        "AD": "HİSARCIK",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1790",
        "IL_KODU": 43,
        "AD": "DUMLUPINAR",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1764",
        "IL_KODU": 43,
        "AD": "ASLANAPA",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1671",
        "IL_KODU": 43,
        "AD": "TAVŞANLI",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1625",
        "IL_KODU": 43,
        "AD": "SİMAV",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1500",
        "IL_KODU": 43,
        "AD": "MERKEZ",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1339",
        "IL_KODU": 43,
        "AD": "GEDİZ",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1304",
        "IL_KODU": 43,
        "AD": "EMET",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1288",
        "IL_KODU": 43,
        "AD": "DOMANİÇ",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "1132",
        "IL_KODU": 43,
        "AD": "ALTINTAŞ",
        "IL_ADI": "KÜTAHYA"
    },
    {
        "KODU": "99137",
        "IL_KODU": 44,
        "AD": "Erhaç Havalimanı",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1995",
        "IL_KODU": 44,
        "AD": "YAZIHAN",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1969",
        "IL_KODU": 44,
        "AD": "KULUNCAK",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1953",
        "IL_KODU": 44,
        "AD": "KALE",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1914",
        "IL_KODU": 44,
        "AD": "DOĞANYOL",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1772",
        "IL_KODU": 44,
        "AD": "BATTALGAZİ",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1729",
        "IL_KODU": 44,
        "AD": "YEŞİLYURT",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1582",
        "IL_KODU": 44,
        "AD": "PÜTÜRGE",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1509",
        "IL_KODU": 44,
        "AD": "MERKEZ",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1390",
        "IL_KODU": 44,
        "AD": "HEKİMHAN",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1286",
        "IL_KODU": 44,
        "AD": "DOĞANŞEHİR",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1265",
        "IL_KODU": 44,
        "AD": "DARENDE",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1148",
        "IL_KODU": 44,
        "AD": "ARGUVAN",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1143",
        "IL_KODU": 44,
        "AD": "ARAPGİR",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "1114",
        "IL_KODU": 44,
        "AD": "AKÇADAĞ",
        "IL_ADI": "MALATYA"
    },
    {
        "KODU": "2087",
        "IL_KODU": 45,
        "AD": "YUNUSEMRE",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "2086",
        "IL_KODU": 45,
        "AD": "ŞEHZADELER",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1965",
        "IL_KODU": 45,
        "AD": "KÖPRÜBAŞI",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1793",
        "IL_KODU": 45,
        "AD": "GÖLMARMARA",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1751",
        "IL_KODU": 45,
        "AD": "AHMETLİ",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1689",
        "IL_KODU": 45,
        "AD": "TURGUTLU",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1634",
        "IL_KODU": 45,
        "AD": "SOMA",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1613",
        "IL_KODU": 45,
        "AD": "SELENDİ",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1606",
        "IL_KODU": 45,
        "AD": "SARUHANLI",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1600",
        "IL_KODU": 45,
        "AD": "SARIGÖL",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1590",
        "IL_KODU": 45,
        "AD": "SALİHLİ",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1513",
        "IL_KODU": 45,
        "AD": "MERKEZ",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1489",
        "IL_KODU": 45,
        "AD": "KULA",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1470",
        "IL_KODU": 45,
        "AD": "KIRKAĞAÇ",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1362",
        "IL_KODU": 45,
        "AD": "GÖRDES",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1269",
        "IL_KODU": 45,
        "AD": "DEMİRCİ",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1127",
        "IL_KODU": 45,
        "AD": "ALAŞEHİR",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "1118",
        "IL_KODU": 45,
        "AD": "AKHİSAR",
        "IL_ADI": "MANİSA"
    },
    {
        "KODU": "99145",
        "IL_KODU": 46,
        "AD": "Kahramanmaraş Havalimanı",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "2085",
        "IL_KODU": 46,
        "AD": "ONİKİŞUBAT",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "2084",
        "IL_KODU": 46,
        "AD": "DULKADİROĞLU",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1975",
        "IL_KODU": 46,
        "AD": "NURHAK",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1919",
        "IL_KODU": 46,
        "AD": "EKİNÖZÜ",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1785",
        "IL_KODU": 46,
        "AD": "ÇAĞLAYANCERİT",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1694",
        "IL_KODU": 46,
        "AD": "TÜRKOĞLU",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1570",
        "IL_KODU": 46,
        "AD": "PAZARCIK",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1515",
        "IL_KODU": 46,
        "AD": "MERKEZ",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1353",
        "IL_KODU": 46,
        "AD": "GÖKSUN",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1299",
        "IL_KODU": 46,
        "AD": "ELBİSTAN",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1136",
        "IL_KODU": 46,
        "AD": "ANDIRIN",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "1107",
        "IL_KODU": 46,
        "AD": "AFŞİN",
        "IL_ADI": "KAHRAMANMARAŞ"
    },
    {
        "KODU": "99114",
        "IL_KODU": 47,
        "AD": "Mardin Havalimanı",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "2088",
        "IL_KODU": 47,
        "AD": "ARTUKLU",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "2002",
        "IL_KODU": 47,
        "AD": "YEŞİLLİ",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1787",
        "IL_KODU": 47,
        "AD": "DARGEÇİT",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1609",
        "IL_KODU": 47,
        "AD": "SAVUR",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1564",
        "IL_KODU": 47,
        "AD": "ÖMERLİ",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1547",
        "IL_KODU": 47,
        "AD": "NUSAYBİN",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1526",
        "IL_KODU": 47,
        "AD": "MİDYAT",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1519",
        "IL_KODU": 47,
        "AD": "MAZIDAĞI",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1516",
        "IL_KODU": 47,
        "AD": "MERKEZ",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1474",
        "IL_KODU": 47,
        "AD": "KIZILTEPE",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "1273",
        "IL_KODU": 47,
        "AD": "DERİK",
        "IL_ADI": "MARDİN"
    },
    {
        "KODU": "99151",
        "IL_KODU": 48,
        "AD": "Milas-Bodrum Havalimanı",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "99125",
        "IL_KODU": 48,
        "AD": "Dalaman Havalimanı",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "2090",
        "IL_KODU": 48,
        "AD": "SEYDİKEMER",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "2089",
        "IL_KODU": 48,
        "AD": "MENTEŞE",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1958",
        "IL_KODU": 48,
        "AD": "KAVAKLIDERE",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1831",
        "IL_KODU": 48,
        "AD": "ORTACA",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1742",
        "IL_KODU": 48,
        "AD": "DALAMAN",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1719",
        "IL_KODU": 48,
        "AD": "YATAĞAN",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1695",
        "IL_KODU": 48,
        "AD": "ULA",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1532",
        "IL_KODU": 48,
        "AD": "MERKEZ",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1528",
        "IL_KODU": 48,
        "AD": "MİLAS",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1517",
        "IL_KODU": 48,
        "AD": "MARMARİS",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1488",
        "IL_KODU": 48,
        "AD": "KÖYCEĞİZ",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1331",
        "IL_KODU": 48,
        "AD": "FETHİYE",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1266",
        "IL_KODU": 48,
        "AD": "DATÇA",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "1197",
        "IL_KODU": 48,
        "AD": "BODRUM",
        "IL_ADI": "MUĞLA"
    },
    {
        "KODU": "99129",
        "IL_KODU": 49,
        "AD": "Muş Havalimanı",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "1964",
        "IL_KODU": 49,
        "AD": "KORKUT",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "1801",
        "IL_KODU": 49,
        "AD": "HASKÖY",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "1711",
        "IL_KODU": 49,
        "AD": "VARTO",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "1534",
        "IL_KODU": 49,
        "AD": "MERKEZ",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "1510",
        "IL_KODU": 49,
        "AD": "MALAZGİRT",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "1213",
        "IL_KODU": 49,
        "AD": "BULANIK",
        "IL_ADI": "MUŞ"
    },
    {
        "KODU": "99105",
        "IL_KODU": 50,
        "AD": "Nevşehir Kapadokya Havalimanı",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1749",
        "IL_KODU": 50,
        "AD": "ACIGÖL",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1707",
        "IL_KODU": 50,
        "AD": "ÜRGÜP",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1543",
        "IL_KODU": 50,
        "AD": "MERKEZ",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1485",
        "IL_KODU": 50,
        "AD": "KOZAKLI",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1374",
        "IL_KODU": 50,
        "AD": "HACIBEKTAŞ",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1367",
        "IL_KODU": 50,
        "AD": "GÜLŞEHİR",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1274",
        "IL_KODU": 50,
        "AD": "DERİNKUYU",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1155",
        "IL_KODU": 50,
        "AD": "AVANOS",
        "IL_ADI": "NEVŞEHİR"
    },
    {
        "KODU": "1904",
        "IL_KODU": 51,
        "AD": "ÇİFTLİK",
        "IL_ADI": "NİĞDE"
    },
    {
        "KODU": "1876",
        "IL_KODU": 51,
        "AD": "ALTUNHİSAR",
        "IL_ADI": "NİĞDE"
    },
    {
        "KODU": "1700",
        "IL_KODU": 51,
        "AD": "ULUKIŞLA",
        "IL_ADI": "NİĞDE"
    },
    {
        "KODU": "1544",
        "IL_KODU": 51,
        "AD": "MERKEZ",
        "IL_ADI": "NİĞDE"
    },
    {
        "KODU": "1225",
        "IL_KODU": 51,
        "AD": "ÇAMARDI",
        "IL_ADI": "NİĞDE"
    },
    {
        "KODU": "1201",
        "IL_KODU": 51,
        "AD": "BOR",
        "IL_ADI": "NİĞDE"
    },
    {
        "KODU": "99163",
        "IL_KODU": 52,
        "AD": "Ordu-Giresun Havalimanı",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "2103",
        "IL_KODU": 52,
        "AD": "ALTINORDU",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1951",
        "IL_KODU": 52,
        "AD": "KABATAŞ",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1950",
        "IL_KODU": 52,
        "AD": "KABADÜZ",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1947",
        "IL_KODU": 52,
        "AD": "İKİZCE",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1900",
        "IL_KODU": 52,
        "AD": "ÇAYBAŞI",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1897",
        "IL_KODU": 52,
        "AD": "ÇATALPINAR",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1891",
        "IL_KODU": 52,
        "AD": "ÇAMAŞ",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1797",
        "IL_KODU": 52,
        "AD": "GÜRGENTEPE",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1795",
        "IL_KODU": 52,
        "AD": "GÜLYALI",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1706",
        "IL_KODU": 52,
        "AD": "ÜNYE",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1696",
        "IL_KODU": 52,
        "AD": "ULUBEY",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1573",
        "IL_KODU": 52,
        "AD": "PERŞEMBE",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1552",
        "IL_KODU": 52,
        "AD": "MERKEZ",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1525",
        "IL_KODU": 52,
        "AD": "MESUDİYE",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1493",
        "IL_KODU": 52,
        "AD": "KUMRU",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1482",
        "IL_KODU": 52,
        "AD": "KORGAN",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1358",
        "IL_KODU": 52,
        "AD": "GÖLKÖY",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1328",
        "IL_KODU": 52,
        "AD": "FATSA",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1158",
        "IL_KODU": 52,
        "AD": "AYBASTI",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1119",
        "IL_KODU": 52,
        "AD": "AKKUŞ",
        "IL_ADI": "ORDU"
    },
    {
        "KODU": "1949",
        "IL_KODU": 53,
        "AD": "İYİDERE",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1943",
        "IL_KODU": 53,
        "AD": "HEMŞİN",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1908",
        "IL_KODU": 53,
        "AD": "DEREPAZARI",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1796",
        "IL_KODU": 53,
        "AD": "GÜNEYSU",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1586",
        "IL_KODU": 53,
        "AD": "MERKEZ",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1569",
        "IL_KODU": 53,
        "AD": "PAZAR",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1428",
        "IL_KODU": 53,
        "AD": "KALKANDERE",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1405",
        "IL_KODU": 53,
        "AD": "İKİZDERE",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1332",
        "IL_KODU": 53,
        "AD": "FINDIKLI",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1241",
        "IL_KODU": 53,
        "AD": "ÇAYELİ",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1228",
        "IL_KODU": 53,
        "AD": "ÇAMLIHEMŞİN",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "1146",
        "IL_KODU": 53,
        "AD": "ARDEŞEN",
        "IL_ADI": "RİZE"
    },
    {
        "KODU": "2071",
        "IL_KODU": 54,
        "AD": "SERDİVAN",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "2070",
        "IL_KODU": 54,
        "AD": "ERENLER",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "2069",
        "IL_KODU": 54,
        "AD": "ARİFİYE",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "2068",
        "IL_KODU": 54,
        "AD": "ADAPAZARI",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1986",
        "IL_KODU": 54,
        "AD": "SÖĞÜTLÜ",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1955",
        "IL_KODU": 54,
        "AD": "KARAPÜRÇEK",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1925",
        "IL_KODU": 54,
        "AD": "FERİZLİ",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1847",
        "IL_KODU": 54,
        "AD": "TARAKLI",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1833",
        "IL_KODU": 54,
        "AD": "PAMUKOVA",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1818",
        "IL_KODU": 54,
        "AD": "KOCAALİ",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1595",
        "IL_KODU": 54,
        "AD": "SAPANCA",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1589",
        "IL_KODU": 54,
        "AD": "MERKEZ",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1453",
        "IL_KODU": 54,
        "AD": "KAYNARCA",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1442",
        "IL_KODU": 54,
        "AD": "KARASU",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1391",
        "IL_KODU": 54,
        "AD": "HENDEK",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1351",
        "IL_KODU": 54,
        "AD": "GEYVE",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "1123",
        "IL_KODU": 54,
        "AD": "AKYAZI",
        "IL_ADI": "SAKARYA"
    },
    {
        "KODU": "99127",
        "IL_KODU": 55,
        "AD": "Samsun Çarşamba Havalimanı",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "2074",
        "IL_KODU": 55,
        "AD": "İLKADIM",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "2073",
        "IL_KODU": 55,
        "AD": "CANİK",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "2072",
        "IL_KODU": 55,
        "AD": "ATAKUM",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1993",
        "IL_KODU": 55,
        "AD": "YAKAKENT",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1879",
        "IL_KODU": 55,
        "AD": "AYVACIK",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1849",
        "IL_KODU": 55,
        "AD": "TEKKEKÖY",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1838",
        "IL_KODU": 55,
        "AD": "SALIPAZARI",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1830",
        "IL_KODU": 55,
        "AD": "19 MAYIS",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1763",
        "IL_KODU": 55,
        "AD": "ASARCIK",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1712",
        "IL_KODU": 55,
        "AD": "VEZİRKÖPRÜ",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1676",
        "IL_KODU": 55,
        "AD": "TERME",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1593",
        "IL_KODU": 55,
        "AD": "MERKEZ",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1501",
        "IL_KODU": 55,
        "AD": "LADİK",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1452",
        "IL_KODU": 55,
        "AD": "KAVAK",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1386",
        "IL_KODU": 55,
        "AD": "HAVZA",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1234",
        "IL_KODU": 55,
        "AD": "ÇARŞAMBA",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1164",
        "IL_KODU": 55,
        "AD": "BAFRA",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "1125",
        "IL_KODU": 55,
        "AD": "ALAÇAM",
        "IL_ADI": "SAMSUN"
    },
    {
        "KODU": "99115",
        "IL_KODU": 56,
        "AD": "Siirt Havalimanı",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1878",
        "IL_KODU": 56,
        "AD": "TİLLO",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1662",
        "IL_KODU": 56,
        "AD": "ŞİRVAN",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1620",
        "IL_KODU": 56,
        "AD": "MERKEZ",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1575",
        "IL_KODU": 56,
        "AD": "PERVARİ",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1495",
        "IL_KODU": 56,
        "AD": "KURTALAN",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1317",
        "IL_KODU": 56,
        "AD": "ERUH",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "1179",
        "IL_KODU": 56,
        "AD": "BAYKAN",
        "IL_ADI": "SİİRT"
    },
    {
        "KODU": "99116",
        "IL_KODU": 57,
        "AD": "Sinop Havalimanı",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1981",
        "IL_KODU": 57,
        "AD": "SARAYDÜZÜ",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1910",
        "IL_KODU": 57,
        "AD": "DİKMEN",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1693",
        "IL_KODU": 57,
        "AD": "TÜRKELİ",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1627",
        "IL_KODU": 57,
        "AD": "MERKEZ",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1349",
        "IL_KODU": 57,
        "AD": "GERZE",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1314",
        "IL_KODU": 57,
        "AD": "ERFELEK",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1290",
        "IL_KODU": 57,
        "AD": "DURAĞAN",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1204",
        "IL_KODU": 57,
        "AD": "BOYABAT",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "1156",
        "IL_KODU": 57,
        "AD": "AYANCIK",
        "IL_ADI": "SİNOP"
    },
    {
        "KODU": "99117",
        "IL_KODU": 58,
        "AD": "Sivas Nuri Demirağ Havalimanı",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1991",
        "IL_KODU": 58,
        "AD": "ULAŞ",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1927",
        "IL_KODU": 58,
        "AD": "GÖLOVA",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1913",
        "IL_KODU": 58,
        "AD": "DOĞANŞAR",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1875",
        "IL_KODU": 58,
        "AD": "ALTINYAYLA",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1870",
        "IL_KODU": 58,
        "AD": "AKINCILAR",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1738",
        "IL_KODU": 58,
        "AD": "ZARA",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1731",
        "IL_KODU": 58,
        "AD": "YILDIZELİ",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1650",
        "IL_KODU": 58,
        "AD": "ŞARKIŞLA",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1646",
        "IL_KODU": 58,
        "AD": "SUŞEHRİ",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1628",
        "IL_KODU": 58,
        "AD": "MERKEZ",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1484",
        "IL_KODU": 58,
        "AD": "KOYULHİSAR",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1431",
        "IL_KODU": 58,
        "AD": "KANGAL",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1407",
        "IL_KODU": 58,
        "AD": "İMRANLI",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1376",
        "IL_KODU": 58,
        "AD": "HAFİK",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1373",
        "IL_KODU": 58,
        "AD": "GÜRÜN",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1342",
        "IL_KODU": 58,
        "AD": "GEMEREK",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "1282",
        "IL_KODU": 58,
        "AD": "DİVRİĞİ",
        "IL_ADI": "SİVAS"
    },
    {
        "KODU": "99119",
        "IL_KODU": 59,
        "AD": "Tekirdağ Çorlu Havalimanı",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "2096",
        "IL_KODU": 59,
        "AD": "SÜLEYMANPAŞA",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "2095",
        "IL_KODU": 59,
        "AD": "KAPAKLI",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "2094",
        "IL_KODU": 59,
        "AD": "ERGENE",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1825",
        "IL_KODU": 59,
        "AD": "MARMARAEREĞLİSİ",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1673",
        "IL_KODU": 59,
        "AD": "MERKEZ",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1652",
        "IL_KODU": 59,
        "AD": "ŞARKÖY",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1596",
        "IL_KODU": 59,
        "AD": "SARAY",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1538",
        "IL_KODU": 59,
        "AD": "MURATLI",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1511",
        "IL_KODU": 59,
        "AD": "MALKARA",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1388",
        "IL_KODU": 59,
        "AD": "HAYRABOLU",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1258",
        "IL_KODU": 59,
        "AD": "ÇORLU",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "1250",
        "IL_KODU": 59,
        "AD": "ÇERKEZKÖY",
        "IL_ADI": "TEKİRDAĞ"
    },
    {
        "KODU": "99169",
        "IL_KODU": 60,
        "AD": "Tokat Havalimanı",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1987",
        "IL_KODU": 60,
        "AD": "SULUSARAY",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1883",
        "IL_KODU": 60,
        "AD": "BAŞÇİFTLİK",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1858",
        "IL_KODU": 60,
        "AD": "YEŞİLYURT",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1834",
        "IL_KODU": 60,
        "AD": "PAZAR",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1740",
        "IL_KODU": 60,
        "AD": "ZİLE",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1690",
        "IL_KODU": 60,
        "AD": "TURHAL",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1679",
        "IL_KODU": 60,
        "AD": "MERKEZ",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1584",
        "IL_KODU": 60,
        "AD": "REŞADİYE",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1545",
        "IL_KODU": 60,
        "AD": "NİKSAR",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1308",
        "IL_KODU": 60,
        "AD": "ERBAA",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1151",
        "IL_KODU": 60,
        "AD": "ARTOVA",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "1129",
        "IL_KODU": 60,
        "AD": "ALMUS",
        "IL_ADI": "TOKAT"
    },
    {
        "KODU": "99152",
        "IL_KODU": 61,
        "AD": "Trabzon Havalimanı",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "2097",
        "IL_KODU": 61,
        "AD": "ORTAHİSAR",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1966",
        "IL_KODU": 61,
        "AD": "KÖPRÜBAŞI",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1942",
        "IL_KODU": 61,
        "AD": "HAYRAT",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1917",
        "IL_KODU": 61,
        "AD": "DÜZKÖY",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1909",
        "IL_KODU": 61,
        "AD": "DERNEKPAZARI",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1896",
        "IL_KODU": 61,
        "AD": "ÇARŞIBAŞI",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1842",
        "IL_KODU": 61,
        "AD": "ŞALPAZARI",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1775",
        "IL_KODU": 61,
        "AD": "BEŞİKDÜZÜ",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1732",
        "IL_KODU": 61,
        "AD": "YOMRA",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1709",
        "IL_KODU": 61,
        "AD": "VAKFIKEBİR",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1686",
        "IL_KODU": 61,
        "AD": "MERKEZ",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1681",
        "IL_KODU": 61,
        "AD": "TONYA",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1647",
        "IL_KODU": 61,
        "AD": "SÜRMENE",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1548",
        "IL_KODU": 61,
        "AD": "OF",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1507",
        "IL_KODU": 61,
        "AD": "MAÇKA",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1244",
        "IL_KODU": 61,
        "AD": "ÇAYKARA",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1150",
        "IL_KODU": 61,
        "AD": "ARSİN",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1141",
        "IL_KODU": 61,
        "AD": "ARAKLI",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1113",
        "IL_KODU": 61,
        "AD": "AKÇAABAT",
        "IL_ADI": "TRABZON"
    },
    {
        "KODU": "1688",
        "IL_KODU": 62,
        "AD": "MERKEZ",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1581",
        "IL_KODU": 62,
        "AD": "PÜLÜMÜR",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1574",
        "IL_KODU": 62,
        "AD": "PERTEK",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1562",
        "IL_KODU": 62,
        "AD": "OVACIK",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1541",
        "IL_KODU": 62,
        "AD": "NAZIMİYE",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1518",
        "IL_KODU": 62,
        "AD": "MAZGİRT",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1397",
        "IL_KODU": 62,
        "AD": "HOZAT",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "1247",
        "IL_KODU": 62,
        "AD": "ÇEMİŞGEZEK",
        "IL_ADI": "TUNCELİ"
    },
    {
        "KODU": "99138",
        "IL_KODU": 63,
        "AD": "Şanlıurfa GAP Havalimanı",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "2093",
        "IL_KODU": 63,
        "AD": "KARAKÖPRÜ",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "2092",
        "IL_KODU": 63,
        "AD": "HALİLİYE",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "2091",
        "IL_KODU": 63,
        "AD": "EYYÜBİYE",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1800",
        "IL_KODU": 63,
        "AD": "HARRAN",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1713",
        "IL_KODU": 63,
        "AD": "VİRANŞEHİR",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1702",
        "IL_KODU": 63,
        "AD": "MERKEZ",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1643",
        "IL_KODU": 63,
        "AD": "SURUÇ",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1630",
        "IL_KODU": 63,
        "AD": "SİVEREK",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1393",
        "IL_KODU": 63,
        "AD": "HİLVAN",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1378",
        "IL_KODU": 63,
        "AD": "HALFETİ",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1220",
        "IL_KODU": 63,
        "AD": "CEYLANPINAR",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1209",
        "IL_KODU": 63,
        "AD": "BOZOVA",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1194",
        "IL_KODU": 63,
        "AD": "BİRECİK",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "1115",
        "IL_KODU": 63,
        "AD": "AKÇAKALE",
        "IL_ADI": "ŞANLIURFA"
    },
    {
        "KODU": "99120",
        "IL_KODU": 64,
        "AD": "Uşak Havalimanı",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "1704",
        "IL_KODU": 64,
        "AD": "MERKEZ",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "1697",
        "IL_KODU": 64,
        "AD": "ULUBEY",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "1629",
        "IL_KODU": 64,
        "AD": "SİVASLI",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "1436",
        "IL_KODU": 64,
        "AD": "KARAHALLI",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "1323",
        "IL_KODU": 64,
        "AD": "EŞME",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "1170",
        "IL_KODU": 64,
        "AD": "BANAZ",
        "IL_ADI": "UŞAK"
    },
    {
        "KODU": "99146",
        "IL_KODU": 65,
        "AD": "Ferit Melen Havalimanı",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "2099",
        "IL_KODU": 65,
        "AD": "TUŞBA",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "2098",
        "IL_KODU": 65,
        "AD": "İPEKYOLU",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1980",
        "IL_KODU": 65,
        "AD": "SARAY",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1918",
        "IL_KODU": 65,
        "AD": "EDREMİT",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1786",
        "IL_KODU": 65,
        "AD": "ÇALDIRAN",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1770",
        "IL_KODU": 65,
        "AD": "BAHÇESARAY",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1710",
        "IL_KODU": 65,
        "AD": "MERKEZ",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1565",
        "IL_KODU": 65,
        "AD": "ÖZALP",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1533",
        "IL_KODU": 65,
        "AD": "MURADİYE",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1372",
        "IL_KODU": 65,
        "AD": "GÜRPINAR",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1350",
        "IL_KODU": 65,
        "AD": "GEVAŞ",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1309",
        "IL_KODU": 65,
        "AD": "ERCİŞ",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1236",
        "IL_KODU": 65,
        "AD": "ÇATAK",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1175",
        "IL_KODU": 65,
        "AD": "BAŞKALE",
        "IL_ADI": "VAN"
    },
    {
        "KODU": "1998",
        "IL_KODU": 66,
        "AD": "YENİFAKILI",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1982",
        "IL_KODU": 66,
        "AD": "SARAYKENT",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1952",
        "IL_KODU": 66,
        "AD": "KADIŞEHRİ",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1895",
        "IL_KODU": 66,
        "AD": "ÇANDIR",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1877",
        "IL_KODU": 66,
        "AD": "AYDINCIK",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1733",
        "IL_KODU": 66,
        "AD": "MERKEZ",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1726",
        "IL_KODU": 66,
        "AD": "YERKÖY",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1655",
        "IL_KODU": 66,
        "AD": "ŞEFAATLİ",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1635",
        "IL_KODU": 66,
        "AD": "SORGUN",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1602",
        "IL_KODU": 66,
        "AD": "SARIKAYA",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1245",
        "IL_KODU": 66,
        "AD": "ÇEKEREK",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1242",
        "IL_KODU": 66,
        "AD": "ÇAYIRALAN",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1198",
        "IL_KODU": 66,
        "AD": "BOĞAZLIYAN",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "1117",
        "IL_KODU": 66,
        "AD": "AKDAĞMADENİ",
        "IL_ADI": "YOZGAT"
    },
    {
        "KODU": "99147",
        "IL_KODU": 67,
        "AD": "Zonguldak Havalimanı",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "2101",
        "IL_KODU": 67,
        "AD": "KOZLU",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "2100",
        "IL_KODU": 67,
        "AD": "KİLİMLİ",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "1926",
        "IL_KODU": 67,
        "AD": "GÖKÇEBEY",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "1758",
        "IL_KODU": 67,
        "AD": "ALAPLI",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "1741",
        "IL_KODU": 67,
        "AD": "MERKEZ",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "1313",
        "IL_KODU": 67,
        "AD": "EREĞLİ",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "1276",
        "IL_KODU": 67,
        "AD": "DEVREK",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "1240",
        "IL_KODU": 67,
        "AD": "ÇAYCUMA",
        "IL_ADI": "ZONGULDAK"
    },
    {
        "KODU": "2106",
        "IL_KODU": 68,
        "AD": "SULTANHANI",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1932",
        "IL_KODU": 68,
        "AD": "GÜLAĞAÇ",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1921",
        "IL_KODU": 68,
        "AD": "ESKİL",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1866",
        "IL_KODU": 68,
        "AD": "SARIYAHŞİ",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1861",
        "IL_KODU": 68,
        "AD": "GÜZELYURT",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1860",
        "IL_KODU": 68,
        "AD": "AĞAÇÖREN",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1557",
        "IL_KODU": 68,
        "AD": "ORTAKÖY",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1120",
        "IL_KODU": 68,
        "AD": "MERKEZ",
        "IL_ADI": "AKSARAY"
    },
    {
        "KODU": "1788",
        "IL_KODU": 69,
        "AD": "DEMİRÖZÜ",
        "IL_ADI": "BAYBURT"
    },
    {
        "KODU": "1767",
        "IL_KODU": 69,
        "AD": "AYDINTEPE",
        "IL_ADI": "BAYBURT"
    },
    {
        "KODU": "1176",
        "IL_KODU": 69,
        "AD": "MERKEZ",
        "IL_ADI": "BAYBURT"
    },
    {
        "KODU": "1983",
        "IL_KODU": 70,
        "AD": "SARIVELİLER",
        "IL_ADI": "KARAMAN"
    },
    {
        "KODU": "1884",
        "IL_KODU": 70,
        "AD": "BAŞYAYLA",
        "IL_ADI": "KARAMAN"
    },
    {
        "KODU": "1862",
        "IL_KODU": 70,
        "AD": "KAZIMKARABEKİR",
        "IL_ADI": "KARAMAN"
    },
    {
        "KODU": "1768",
        "IL_KODU": 70,
        "AD": "AYRANCI",
        "IL_ADI": "KARAMAN"
    },
    {
        "KODU": "1439",
        "IL_KODU": 70,
        "AD": "MERKEZ",
        "IL_ADI": "KARAMAN"
    },
    {
        "KODU": "1316",
        "IL_KODU": 70,
        "AD": "ERMENEK",
        "IL_ADI": "KARAMAN"
    },
    {
        "KODU": "1992",
        "IL_KODU": 71,
        "AD": "YAHŞİHAN",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1954",
        "IL_KODU": 71,
        "AD": "KARAKEÇİLİ",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1901",
        "IL_KODU": 71,
        "AD": "ÇELEBİ",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1882",
        "IL_KODU": 71,
        "AD": "BALIŞEYH",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1880",
        "IL_KODU": 71,
        "AD": "BAHŞİLİ",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1638",
        "IL_KODU": 71,
        "AD": "SULAKYURT",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1469",
        "IL_KODU": 71,
        "AD": "MERKEZ",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1463",
        "IL_KODU": 71,
        "AD": "KESKİN",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "1268",
        "IL_KODU": 71,
        "AD": "DELİCE",
        "IL_ADI": "KIRIKKALE"
    },
    {
        "KODU": "99108",
        "IL_KODU": 72,
        "AD": "Batman Havalimanı",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "1941",
        "IL_KODU": 72,
        "AD": "HASANKEYF",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "1607",
        "IL_KODU": 72,
        "AD": "SASON",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "1487",
        "IL_KODU": 72,
        "AD": "KOZLUK",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "1345",
        "IL_KODU": 72,
        "AD": "GERCÜŞ",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "1184",
        "IL_KODU": 72,
        "AD": "BEŞİRİ",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "1174",
        "IL_KODU": 72,
        "AD": "MERKEZ",
        "IL_ADI": "BATMAN"
    },
    {
        "KODU": "99118",
        "IL_KODU": 73,
        "AD": "Şırnak Şerafettin Elçi Havalimanı",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1931",
        "IL_KODU": 73,
        "AD": "GÜÇLÜKONAK",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1698",
        "IL_KODU": 73,
        "AD": "ULUDERE",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1661",
        "IL_KODU": 73,
        "AD": "MERKEZ",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1623",
        "IL_KODU": 73,
        "AD": "SİLOPİ",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1403",
        "IL_KODU": 73,
        "AD": "İDİL",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1223",
        "IL_KODU": 73,
        "AD": "CİZRE",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1189",
        "IL_KODU": 73,
        "AD": "BEYTÜŞŞEBAP",
        "IL_ADI": "ŞIRNAK"
    },
    {
        "KODU": "1761",
        "IL_KODU": 74,
        "AD": "AMASRA",
        "IL_ADI": "BARTIN"
    },
    {
        "KODU": "1701",
        "IL_KODU": 74,
        "AD": "ULUS",
        "IL_ADI": "BARTIN"
    },
    {
        "KODU": "1496",
        "IL_KODU": 74,
        "AD": "KURUCAŞİLE",
        "IL_ADI": "BARTIN"
    },
    {
        "KODU": "1172",
        "IL_KODU": 74,
        "AD": "MERKEZ",
        "IL_ADI": "BARTIN"
    },
    {
        "KODU": "2008",
        "IL_KODU": 75,
        "AD": "DAMAL",
        "IL_ADI": "ARDAHAN"
    },
    {
        "KODU": "1579",
        "IL_KODU": 75,
        "AD": "POSOF",
        "IL_ADI": "ARDAHAN"
    },
    {
        "KODU": "1380",
        "IL_KODU": 75,
        "AD": "HANAK",
        "IL_ADI": "ARDAHAN"
    },
    {
        "KODU": "1356",
        "IL_KODU": 75,
        "AD": "GÖLE",
        "IL_ADI": "ARDAHAN"
    },
    {
        "KODU": "1252",
        "IL_KODU": 75,
        "AD": "ÇILDIR",
        "IL_ADI": "ARDAHAN"
    },
    {
        "KODU": "1144",
        "IL_KODU": 75,
        "AD": "MERKEZ",
        "IL_ADI": "ARDAHAN"
    },
    {
        "KODU": "99111",
        "IL_KODU": 76,
        "AD": "Iğdır Havalimanı",
        "IL_ADI": "IĞDIR"
    },
    {
        "KODU": "2011",
        "IL_KODU": 76,
        "AD": "KARAKOYUNLU",
        "IL_ADI": "IĞDIR"
    },
    {
        "KODU": "1692",
        "IL_KODU": 76,
        "AD": "TUZLUCA",
        "IL_ADI": "IĞDIR"
    },
    {
        "KODU": "1398",
        "IL_KODU": 76,
        "AD": "MERKEZ",
        "IL_ADI": "IĞDIR"
    },
    {
        "KODU": "1142",
        "IL_KODU": 76,
        "AD": "ARALIK",
        "IL_ADI": "IĞDIR"
    },
    {
        "KODU": "2026",
        "IL_KODU": 77,
        "AD": "TERMAL",
        "IL_ADI": "YALOVA"
    },
    {
        "KODU": "2022",
        "IL_KODU": 77,
        "AD": "ÇİFTLİKKÖY",
        "IL_ADI": "YALOVA"
    },
    {
        "KODU": "2021",
        "IL_KODU": 77,
        "AD": "ÇINARCIK",
        "IL_ADI": "YALOVA"
    },
    {
        "KODU": "2020",
        "IL_KODU": 77,
        "AD": "ARMUTLU",
        "IL_ADI": "YALOVA"
    },
    {
        "KODU": "2019",
        "IL_KODU": 77,
        "AD": "ALTINOVA",
        "IL_ADI": "YALOVA"
    },
    {
        "KODU": "1716",
        "IL_KODU": 77,
        "AD": "MERKEZ",
        "IL_ADI": "YALOVA"
    },
    {
        "KODU": "1856",
        "IL_KODU": 78,
        "AD": "YENİCE",
        "IL_ADI": "KARABÜK"
    },
    {
        "KODU": "1587",
        "IL_KODU": 78,
        "AD": "SAFRANBOLU",
        "IL_ADI": "KARABÜK"
    },
    {
        "KODU": "1561",
        "IL_KODU": 78,
        "AD": "OVACIK",
        "IL_ADI": "KARABÜK"
    },
    {
        "KODU": "1433",
        "IL_KODU": 78,
        "AD": "MERKEZ",
        "IL_ADI": "KARABÜK"
    },
    {
        "KODU": "1321",
        "IL_KODU": 78,
        "AD": "ESKİPAZAR",
        "IL_ADI": "KARABÜK"
    },
    {
        "KODU": "1296",
        "IL_KODU": 78,
        "AD": "EFLANİ",
        "IL_ADI": "KARABÜK"
    },
    {
        "KODU": "2025",
        "IL_KODU": 79,
        "AD": "POLATELİ",
        "IL_ADI": "KİLİS"
    },
    {
        "KODU": "2024",
        "IL_KODU": 79,
        "AD": "MUSABEYLİ",
        "IL_ADI": "KİLİS"
    },
    {
        "KODU": "2023",
        "IL_KODU": 79,
        "AD": "ELBEYLİ",
        "IL_ADI": "KİLİS"
    },
    {
        "KODU": "1476",
        "IL_KODU": 79,
        "AD": "MERKEZ",
        "IL_ADI": "KİLİS"
    },
    {
        "KODU": "2029",
        "IL_KODU": 80,
        "AD": "TOPRAKKALE",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "2028",
        "IL_KODU": 80,
        "AD": "SUMBAS",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "2027",
        "IL_KODU": 80,
        "AD": "HASANBEYLİ",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "1743",
        "IL_KODU": 80,
        "AD": "DÜZİÇİ",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "1560",
        "IL_KODU": 80,
        "AD": "MERKEZ",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "1423",
        "IL_KODU": 80,
        "AD": "KADİRLİ",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "1165",
        "IL_KODU": 80,
        "AD": "BAHÇE",
        "IL_ADI": "OSMANİYE"
    },
    {
        "KODU": "2031",
        "IL_KODU": 81,
        "AD": "KAYNAŞLI",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "2017",
        "IL_KODU": 81,
        "AD": "GÜMÜŞOVA",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "1905",
        "IL_KODU": 81,
        "AD": "ÇİLİMLİ",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "1794",
        "IL_KODU": 81,
        "AD": "GÖLYAKA",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "1784",
        "IL_KODU": 81,
        "AD": "CUMAYERİ",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "1730",
        "IL_KODU": 81,
        "AD": "YIĞILCA",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "1292",
        "IL_KODU": 81,
        "AD": "MERKEZ",
        "IL_ADI": "DÜZCE"
    },
    {
        "KODU": "1116",
        "IL_KODU": 81,
        "AD": "AKÇAKOCA",
        "IL_ADI": "DÜZCE"
    }
]