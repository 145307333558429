import { useEffect, useRef, useState } from "react";

export const CountrySelectBox = ({ items, onChange, placeholder }) => {

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const searchInputRef = useRef(null);

    const handleSearchTermChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
        setSearchTerm(option.ULKE_ADI);
        onChange(option);
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDocumentClick = (e) => {
        if (!searchInputRef.current.contains(e.target)) {
            setIsDropdownOpen(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Escape') {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const filteredOptions = items.filter((option) => {
        const { KOD, ULKE_ADI } = option;
        const lowerSearchTerm = searchTerm.toLowerCase();
        return (
            KOD.toLowerCase().includes(lowerSearchTerm) ||
            ULKE_ADI.toLowerCase().includes(lowerSearchTerm)
        );
    });

    return (
        <div className="search-select-box" ref={searchInputRef}>
            <input
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleSearchTermChange}
            />
            <div className="dropdown">
                <div className="dropdown-menu">
                    {filteredOptions.map((option) => (
                        <div
                            key={option.KOD}
                            className="dropdown-item"
                            onClick={() => handleOptionSelect(option)}
                        >
                            <span>{option.ULKE_ADI}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

}