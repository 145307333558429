import { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom"

export const Menu = ({ style, setMenuState }) => {
    const items = [
        {
            itemName: "Sürücü Ekle",
            itemLink: "/drivers/create",
            itemVisible: localStorage.getItem("accountIsCompany") === "true"
        },
        {
            itemName: "Sürücüler",
            itemLink: "/drivers",
            itemVisible: localStorage.getItem("accountIsCompany") === "true"
        },
        {
            itemName: "Ana sayfa",
            itemLink: "/",
            itemVisible: true
        },
        {
            itemName: "Sefer Ekle",
            itemLink: "/reports/create",
            itemVisible: true
        },
        {
            itemName: "Seferler",
            itemLink: "/reports",
            itemVisible: true
        },
    ]
    const Route = (item) => {
        if (isMobile) {
            setMenuState(false)
        }
    }
    return (
        <div className="menu" style={style}>
            {items.map(i =>
                i.itemVisible ? <Link className="item" to={i.itemLink} onClick={Route}>{i.itemName}</Link> : null
            )}
        </div>
    )
}