import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Home } from "../Pages/Home";
import { Header } from "./Header";
import { Menu } from "./Menu";
import { ReportCreate } from "../Pages/Report/Create";
import { ReportList } from "../Pages/Report/List";
import { AccountAuth, Auth } from "../Pages/Account/Auth";
import { AccountCreate } from "../Pages/Account/Create";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { DriverCreate } from "../Pages/Drivers/Create";
import { DriverList } from "../Pages/Drivers/List";
import { Toaster } from "react-hot-toast";

export const PageRoutes = () => {
    const [menuState, setMenuState] = useState()

    useEffect(() => {
        setMenuState(localStorage.getItem("accountId") ? true : false)
    }, [])
    return (
        <BrowserRouter>
            <Menu style={menuState ? { display: "flex" } : { display: "none" }} />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/account/auth" element={<AccountAuth setMenuState={setMenuState} />} />
                <Route path="/account/create" element={<AccountCreate setMenuState={setMenuState} />} />
                <Route path="/reports/create" element={<ReportCreate />} />
                <Route path="/reports" element={<ReportList />} />
                <Route path="/drivers/create" element={<DriverCreate />} />
                <Route path="/drivers" element={<DriverList />} />
            </Routes>
            <Toaster />
        </BrowserRouter>
    )
}