import {useEffect, useState} from "react"
import { apiUrl } from "../../Utils/Consts"
import { useNavigate } from "react-router-dom"

export const DriverCreate = () => {
    const [driverName, setDriverName] = useState("")
    const [driverEmail, setDriverEmail] = useState("")
    const [driverIdentity, setDriverIdentity] = useState("")
    const [driverCarPlate, setDriverCarPlate] = useState("")
    const [driverPassword, setDriverPassword] = useState("")
    const [driverPasswordAgain, setDriverPasswordAgain] = useState("")
    const [driverCreateState, setDriverCreateState] = useState()
    const [company, setCompany] = useState("");
    const [companies, setCompanies] = useState([]);
    const navigate = useNavigate()

    const getCompanies = () => {
        fetch(`${apiUrl}/company/list`)
            .then(res => res.json())
            .then(
                (res) => {
                    console.log(res)
                    if (res.responseStatus) {
                        setCompanies(res.companyList)
                    }
                },
                (error) => {

                }
            )
    }

    useEffect(() => {
        getCompanies();
    }, []);
    const driverCreate = () => {
        if (driverName == "" || driverEmail == "" || driverCarPlate == "" || driverPassword == "" || driverPasswordAgain == "" || driverIdentity == "") {
            setDriverCreateState("Boş alanları doldurun.")
        }
        else {
            if (driverPassword != driverPasswordAgain) {
                setDriverCreateState("Şifreler uyuşmuyor, lütfen kontrol edin.")
            }
            else {
                setDriverCreateState()
                const requestBody = {
                    companyId: company,
                    driverName: driverName,
                    driverEmail: driverEmail,
                    driverIdentity: driverIdentity,
                    driverVehiclePlate: driverCarPlate,
                    driverPassword: driverPassword,
                    driverPasswordAgain: driverPasswordAgain
                }
                const requestMetadata = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody)
                }
                fetch(`${apiUrl}/driver/create`, requestMetadata)
                    .then(res => res.json())
                    .then(
                        (res) => {
                            console.log(res)
                            if (res.responseStatus) {
                                navigate("/drivers")
                            }
                            else {
                                setDriverCreateState(res.responseMessage)
                            }
                        },
                        (error) => {
                        }
                    )
            }
        }
    }
    return (
        <div className="driver-create">
            <span className="title">Sürücü Ekle</span>
            <span className="info">
                <ul>
                    <li>İsim ve plaka kısmına sürücünün ad ve soyadını, araç plaka kısmına ise aracın plakasını (Örn. 12AB3456) girin.</li>
                    <li>Sürücü e-posta ve şifre kısmına sürücünün giriş yapacağı e-posta ve şifreyi girin.</li>
                </ul>
            </span>
            <div className="group">
                <div className="section">
                    <span className="label">Şirket</span>
                    <select
                        onChange={e => setCompany(e.target.value)}>
                        <option>Şirket seçiniz</option>
                        {companies.map(e =>
                            <option
                                key={e.companyId}
                                value={e.companyId}
                            >
                                {e.companyName}
                            </option>
                        )}
                    </select>
                </div>
            </div>
            <div className="group">
                <div className="section">
                    <span className="label">İsim</span>
                    <input type="text" placeholder="İsim" onChange={e => setDriverName(e.target.value)}/>
                </div>
            </div>
            <div className="group">
                <div className="section">
                    <span className="label">TC Kimlik Numarası</span>
                    <input type="text" placeholder="TC Kimlik Numarası"
                           onChange={e => setDriverIdentity(e.target.value)}/>
                </div>
                <div className="section">
                    <span className="label">Araç Plaka</span>
                    <input type="text" placeholder="Araç Plaka" onChange={e => setDriverCarPlate(e.target.value)} />
                </div>
            </div>
            <div className="section">
                <span className="label">Sürücü E-posta</span>
                <input type="text" placeholder="E-posta" onChange={e => setDriverEmail(e.target.value)} />
            </div>
            <div className="group">
                <div className="section">
                    <span className="label">Sürücü Şifresi</span>
                    <input type="password" placeholder="Şifre" onChange={e => setDriverPassword(e.target.value)} />
                </div>
                <div className="section">
                    <span className="label">Sürücü Şifresi Tekrar</span>
                    <input type="password" placeholder="Şifre" onChange={e => setDriverPasswordAgain(e.target.value)} />
                </div>
            </div>
            <br />
            <span className="button" onClick={driverCreate}>Gönder</span>
            <span className="error">{driverCreateState}</span>
            <br />
            <br />
        </div>
    )
}