import { useEffect, useState } from "react"
import { apiUrl } from "../../Utils/Consts"
import { Link, useNavigate } from "react-router-dom"
import { isMobile } from "react-device-detect"

export const AccountAuth = ({ setMenuState }) => {
    const [accountEmail, setAccountEmail] = useState("")
    const [accountPassword, setAccountPassword] = useState("")
    const [errorMessage, setErrorMessage] = useState()
    const navigate = useNavigate()
    const accountAuth = () => {
        const requestBody = {
            accountEmail: accountEmail,
            accountPassword: accountPassword
        }
        const requestMetadata = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        }
        fetch(`${apiUrl}/account/auth`, requestMetadata)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        console.log(res)
                        localStorage.setItem("accountId", res.accountData.accountId)
                        localStorage.setItem("accountIsCompany", res.accountData.accountIsCompany == 1 ? true : false)
                        navigate("/")
                        if (!isMobile) {
                            console.log(setMenuState)
                            setMenuState(true)
                        }
                    }
                    else {
                        setErrorMessage(res.responseMessage)
                    }
                },
                (error) => {
                }
            )
    }
    useEffect(() => {
        if (localStorage.getItem("userId") == null) {
            navigate("/account/auth")
        }
    }, [])
    return (
        <div className="account-auth _page _page_auth">
            <h1>GoTransfer U-ETDS Hizmetleri</h1>
            <h2>Oturum Aç</h2>
            <div className="section">
                <span className="label">E-posta</span>
                <input type="text" placeholder="E-posta" onChange={e => setAccountEmail(e.target.value)} />
            </div>
            <div className="section">
                <span className="label">Şifre</span>
                <input type="password" placeholder="Şifre" onChange={e => setAccountPassword(e.target.value)} />
            </div>
            <span className="button" onClick={() => accountAuth()}>Giriş Yap</span>
            <span className="error">{errorMessage}</span>
            <br />
            <br />
            <span>Ya da Ücretsiz Hesap Oluştur</span>
            <br />
            <Link className="button link" to="/account/create">Hesap Oluştur</Link>
        </div>
    )
}