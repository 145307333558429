import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Loader, apiUrl } from "../Utils/Consts"

export const Home = () => {
    const navigate = useNavigate()
    const [accountData, setAccountData] = useState()
    const getAccountData = () => {
        fetch(`${apiUrl}/account/data?accountId=${localStorage.getItem("accountId")}`)
            .then(res => res.json())
            .then(
                (res) => {
                    if (res.responseStatus) {
                        setAccountData(res)
                    }
                },
                (error) => {
                }
            )
    }
    const accountLogout = () => {
        localStorage.removeItem("accountId");
        navigate("/account/auth");
    }
    useEffect(() => {
        if (localStorage.getItem("accountId") == null) {
            navigate("/account/auth")
        }
        else {
            getAccountData()

        }
    }, [])
    return (
        accountData ?
            <div className="_page_home _page">
                <span>{accountData.account_data["accountName"]}</span>
                <span>{accountData.account_data["accountCarPlate"]}</span>
                {accountData.account_uetds_data["sonucKodu"] == 0 ?
                    <div>
                        <h3>UETDS sistemine hoş geldiniz, menüden yapmak istediğiniz işlemi seçebilirsiniz.</h3>
                    </div>
                    :
                    <div className="contact">
                        <h3>{accountData.account_uetds_data["sonucMesaji"]}</h3>
                        <span>Belgeniz bulunamadı veya aktif değil, belge işlemleri için bize ulaşabilirsiniz.</span>
                        <span>+90 552 826 48 47</span>
                        <a href="https://api.whatsapp.com/send/?phone=905528264847">WhatsApp</a>
                    </div>
                }
                <div className="logout button" onClick={() => accountLogout()}>
                    <span>Çıkış Yap</span>
                </div>
            </div>
            :
            <Loader />
    )
}