import { useState } from "react"
import { apiUrl } from "../../Utils/Consts"
import { Link, useNavigate } from "react-router-dom"
import { isMobile } from "react-device-detect"

export const AccountCreate = ({ setMenuState }) => {
    const [companyName, setCompanyName] = useState("")
    const [companyUetdsName, setCompanyUetdsName] = useState("")
    const [companyUetdsPassword, setCompanyUetdsPassword] = useState("")
    const [accountEmail, setAccountEmail] = useState("")
    const [accountPassword, setAccountPassword] = useState("")
    const [accountPasswordAgain, setAccountPasswordAgain] = useState("")
    const [errorMessage, setErrorMessage] = useState()
    const navigate = useNavigate()

    const accountCreate = () => {
        setErrorMessage()
        if (companyName.trim() == "" || companyUetdsName.trim() == "" || companyUetdsPassword.trim() == "" || accountEmail.trim() == "" || accountPassword.trim() == "" || accountPasswordAgain.trim() == "") {
            setErrorMessage("Boş alanları doldurunuz.")
        }
        else {
            if (accountPassword != accountPasswordAgain) {
                setErrorMessage("Şifreler eşleşmiyor, lütfen kontrol edin.")
            }
            else {
                const requestBody = {
                    companyName: companyName,
                    companyUetdsName: companyUetdsName,
                    companyUetdsPassword: companyUetdsPassword,
                    accountEmail: accountEmail,
                    accountPassword: accountPassword
                }
                const requestMetadata = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(requestBody)
                }
                fetch(`${apiUrl}/account/create`, requestMetadata)
                    .then(res => res.json())
                    .then(
                        (res) => {
                            if (res.responseStatus) {
                                localStorage.setItem("accountId", res.accountId)
                                localStorage.setItem("accountIsCompany", true)
                                navigate("/")
                                if (!isMobile) {
                                    setMenuState(true)
                                }
                            }
                            else {
                                setErrorMessage(res.responseMessage)
                            }
                        },
                        (error) => {
                        }
                    )
            }

        }
    }
    return (
        <div className="account-create">
            <span className="title">Kayıt Ol</span>
            <br />
            <span className="info">
                <ul>
                    <li>Kullanıcı Hizmet Sözleşmemizi okuyunuz.</li>
                    <li>Edevlet'e giriş yaparak uetds tüzel/gerçek kişi IP tanımlaması IP başlangıç bölümüne (<b>164.90.160.20</b>) numarasını kaydedin.</li>
                    <li>IP listesindeki "Tutanak Görüntüle" tuşuna basarak, ilgili belgedeki "Gerçek kullanıcı adı" ve "Gerçek Şifre" alanlarını aşağıdaki forma giriniz.</li>
                    <li>Hizmet seçeneklerimizden birisini seçerek ödemenizi gerçekleştiriniz.</li>
                    <li>Tarafımızdan, gerekli kontroller yapıldıktan sonra UETDS uygulama hesabınız açılacak ve kullanıma başlayabileceksiniz.</li>
                </ul>
            </span>
            <div className="section">
                <span className="label">Şirket ya da Firma Adı</span>
                <input type="text" placeholder="İsim" onChange={e => setCompanyName(e.target.value)} />
            </div>
            <div className="group">
                <div className="section">
                    <span className="label">Uetds Kullanıcı Adı</span>
                    <input type="text" placeholder="UETDS Kullanici Adi" onChange={e => setCompanyUetdsName(e.target.value)} />
                </div>
                <div className="section">
                    <span className="label">Uetds Şifre</span>
                    <input type="password" placeholder="UETDS Kullanici Sifresi" onChange={e => setCompanyUetdsPassword(e.target.value)} />
                </div>
            </div>
            <div className="section">
                <span className="label">E-posta</span>
                <input type="text" placeholder="Eposta" onChange={e => setAccountEmail(e.target.value)} />
            </div>
            <div className="group">
                <div className="section">
                    <span className="label">Şifre</span>
                    <input type="password" placeholder="Şifre" onChange={e => setAccountPassword(e.target.value)} />
                </div>
                <div className="section">
                    <span className="label">Şifre Tekrar</span>
                    <input type="password" placeholder="Şifre Tekrar" onChange={e => setAccountPasswordAgain(e.target.value)} />
                </div>
            </div>
            <span className="button" onClick={() => accountCreate()}>Hesap olustur</span>
            <span className="error">{errorMessage}</span>
            <br />
            <br />
            <span>Ya da</span>
            <br />
            <Link className="button link" to="/account/auth">Giriş Yap</Link>
            <br />
            <br />
        </div>
    )
}